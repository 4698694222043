import { faExclamationTriangle, faReply } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Link } from 'react-router-dom'
import { Button } from 'reactstrap'

interface NotFoundProps {

}

const NotFound: React.FC<NotFoundProps> = (props) => {
  return (
    <div className='vh-100 w-100 d-flex'>
      <div className='w-100 m-auto text-center'>
          <h1 className='display-1 text-danger'>
            <FontAwesomeIcon icon={faExclamationTriangle}/>
          </h1>
          <h1 className='display-3 m-4'>Oops!</h1>
          <h2 className='text-muted m-4'>404 Page Not Found</h2> 
          <Button className='m-4' size="lg" color='info' tag={Link} to="/">
            <FontAwesomeIcon icon={faReply} className='mr-4' />
            Back to Home
          </Button>
      </div>
    </div>
  )
}

export default NotFound
