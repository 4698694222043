import { useEffect, useCallback, useReducer } from 'react'
import { useMemo } from 'react'
import isEqual from 'lodash/isEqual'
import getAttachments from './getAttachments'
import getAttachmentSubtypes from './getAttachmentSubtypes'
import uploadAttachment from './uploadAttachment'
import updateAttachment from './updateAttachment'
import deleteAttachment from './deleteAttachment'
import attachmentReducer, {
  AttachmentData,
  AttachmentState,
} from './attachmentReducer'

export interface AttachmentMethods {
  get: () => Promise<void>,
  del: (index: number) => void,
  undel: (index: number) => void,
  add: () => void
  onFileChange: (index: number, file: File | undefined) => void
  onSubtypeChange: (index: number, subtype: string) => void
  save: (data: Partial<AttachmentData>) => Promise<void>
  updateData: (data: Partial<AttachmentData>) => void
}

type UseAttachments = (data: Partial<AttachmentData>) => [
  AttachmentState,
  AttachmentMethods
]

/**
 * Search for attachments using the attachment type and the linking ObjectId.
 * Additional keys can use to fine tune the search. All additional query keys are
 * store in the 'additional' key on the attachment object.
 *  
 */
const useAttachments: UseAttachments = (data) => {
  const [ state, dispatch ] = useReducer(attachmentReducer, {
    attachments: [],
    subtypes: [],
    loading: false,
    data,
    canSave: true,
    dirty: false
  })
  const { attachments, data: stateData, canSave } = state
  const { type, link, additional } = stateData

  useEffect(() => {
    if(!isEqual(state.data, data)) {
      dispatch({ type: 'updateData', payload: data })
    }
  }, [state.data, data])

  useEffect(() => {
    const loadAttachmentSubtypes = async () => {
      dispatch({ type: 'loading' })
      try {
        if(typeof type === 'string') {
          const subtypes = await getAttachmentSubtypes(type) 
          if(Array.isArray(subtypes)) {
            dispatch({ type: 'subtypes', payload: subtypes })
          }
        }
      } catch (e) {
        throw e
      } finally {
        dispatch({ type: 'loading', payload: false })
      }
    }
    loadAttachmentSubtypes()
  }, [type])

  const get = useCallback(async () => {
    dispatch({ type: 'loading' })
    try {
      if(
        type !== undefined &&
        link !== undefined
      ) {
        const items = await getAttachments(type, link, additional) 
        if(Array.isArray(items)) {
          dispatch({ type: 'attachments', payload: items })
        }
      }
    } catch (e) {
      throw e
    } finally {
      dispatch({ type: 'loading', payload: false })
    }
  }, [type, link, additional])


  const del = useCallback((index) => {
    dispatch({ type: 'delete', index })
  }, [])

  const undel = useCallback((index) => {
    dispatch({ type: 'undelete', index })
  }, [])

  const add = useCallback(() => {
    dispatch({ type: 'add' })
  }, [])

  const onFileChange = useCallback((index, file) => {
    dispatch({ type: 'onFileChange', index, payload: file })
  }, [])
  
  const onSubtypeChange = useCallback((index: number, subtype: string) => {
    dispatch({ type: 'onSubtypeChange', index, payload: subtype })
  }, [])

  const save = useCallback(async (data) => {
    if(canSave) {
      const mergedData = Object.assign({}, stateData, data)
      const uploads = attachments.map((a, index) => {
        if(!("_id" in a) && "file" in a) {
          const attachmentData = { ...mergedData, subtype: a.subtype}
          return uploadAttachment({
            ...attachmentData,
            file: a.file
          }, (percent) => {
            dispatch({ type: 'progress', index, payload: percent })
          })
        }
        return null
      })
      const deletes = attachments.map(a => {
        if("delete" in a && a.delete === true) {
          return deleteAttachment(a._id)
        }
        return null
      })
      const saves = attachments.map(a => {
        if("_id" in a && a.subtype !== a.originalSubtype) {
          return updateAttachment(a._id, { subtype: a.subtype})
        }
        return null
      })
      const promises = [...uploads, ...deletes, ...saves] as Array<Promise<any>>
      await Promise.all(promises)
      dispatch({ type: 'updateData', payload: data })
    } else {
      throw new Error("Can't save one of the attachments becuase it is too large.")
    }
  }, [attachments, stateData, canSave])

  const updateData = useCallback((data) =>  {
    dispatch({ type: 'updateData', payload: data })
  }, [])

  const methods = useMemo(() => ({
    get,
    del,
    undel,
    add,
    onFileChange,
    onSubtypeChange,
    save,
    updateData
  }), [get, del, undel, add, onFileChange, onSubtypeChange, save, updateData])

  return [ state, methods ]
}

export default useAttachments
