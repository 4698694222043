import React from 'react'
import { Row, Col } from 'reactstrap'
import { DatePicker } from '@imcva/formik-reactstrap-widgets'

interface OfferLetterProps {
  active: boolean
}

const OfferLetter: React.FC<OfferLetterProps> = (props) => {
  if (props.active) {
    return (
      <Row>
        <Col md={6}>
          <DatePicker name='offer_letter.extended' label='Extended' />
        </Col>
        <Col md={6}>
          <DatePicker name='offer_letter.signed' label='Signed' />
        </Col>
      </Row>
    )
  } else {
    return null
  }
}

export default OfferLetter
