import React from 'react'
import { Row, Col } from 'reactstrap'
import { DatePicker } from '@imcva/formik-reactstrap-widgets'

interface ExhibitProps {
  active: boolean
}

const Exhibit: React.FC<ExhibitProps> = (props) => {
  if (props.active) {
    return (
      <Row>
        <Col md={6}>
          <DatePicker name='exhibit.sent_for_review' label='Sent For Review' />
        </Col>
        <Col md={6}>
          <DatePicker name='exhibit.hm_approved' label='Hiring Manager Approved' />
        </Col>
        <Col md={6}>
          <DatePicker name='exhibit.contracts_approved' label='Contracts Approved' />
        </Col>
        <Col md={6}>
          <DatePicker name='exhibit.signed' label='Signed On' />
        </Col>
        <Col md={6}>
          <DatePicker name='exhibit.pmo_approved' label='PMO Approved' />
        </Col>
      </Row>
    )
  } else {
    return null
  }
}

export default Exhibit
