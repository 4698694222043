import React from 'react'
import { CellBackground } from '../../../../components/TableHelpers'

interface MetricCountProps {
  percentageTotal?: number,
  value: number
  showBackground?: boolean
  backgroundColor?: string
}
interface BackgroundProps {
  percentage?: number,
  showBackground?: boolean
  color?: string
}

const Background: React.FC<BackgroundProps> = (props) => {
  if(props.showBackground) {
    return (
      <CellBackground
        percentage={props.percentage}
        color={props.color ? props.color : 'primary'}
      >
        {props.children}
      </CellBackground>
    )
  } else {
    return (
      <>
        {props.children}
      </>
    )
  }
}

const MetricCount: React.FC<MetricCountProps> = (props) => {
  const { value, percentageTotal, showBackground, backgroundColor } = props
  
  const percentage = percentageTotal !== undefined && percentageTotal !== 0 && value !== 0
        ? Math.round((value / percentageTotal)*100)
        : 0
        
  return (
    <Background
      percentage={percentage}
      showBackground={showBackground}
      color={backgroundColor}
    >
      <span className='mr-1'>
        {percentage ? `${percentage}%` : '-'}
      </span>
      <small className='text-muted'>({value})</small>
    </Background>
  )
}

export default MetricCount
