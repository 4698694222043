import React, { useState } from 'react'
import { 
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';
import {
  sub,
  startOfWeek,
  endOfWeek,
  startOfMonth,
  startOfYear
} from 'date-fns'
import { endOfMonth, endOfYear } from 'date-fns/esm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';

interface Option {
  label: string,
  to: Date
  from: Date 
}

interface DateRangeQuickSelectorProps {
  onChange: (range: Option) => void
}

const today = new Date()

const options = [
  {
    label: 'Last 7 Days',
    from: sub(today, { days: 7}),
    to: today
  },
  {
    label: 'Last Week',
    from: sub(startOfWeek(today), {weeks: 1}),
    to: sub(endOfWeek(today), {weeks: 1})
  },
  {
    label: 'This Month',
    from: startOfMonth(today),
    to: endOfMonth(today)
  },
  {
    label: 'Last Month',
    from: sub(startOfMonth(today), {months: 1}),
    to: endOfMonth(sub(startOfMonth(today), {months: 1})),
  },
  {
    label: 'Past Year',
    from: sub(today, {years: 1}),
    to: sub(today, {days: 1})
  },
  {
    label: 'Last Calendar Year',
    from: sub(startOfYear(today), {years: 1}),
    to: endOfYear(sub(startOfYear(today), {years: 1})),
  },
]

const DateRangeQuickSelector: React.FC<DateRangeQuickSelectorProps> = (props) => {
  const [dropdownOpen, setOpen] = useState(false);

  const toggle = () => setOpen(!dropdownOpen);
 
  return (
    <ButtonDropdown isOpen={dropdownOpen} toggle={toggle} size="sm">
      <DropdownToggle caret>
        <FontAwesomeIcon icon={faCalendar} />
      </DropdownToggle>
      <DropdownMenu>
        {options.map(opt => {
          return <DropdownItem onClick={() => props.onChange(opt)}>{opt.label}</DropdownItem>
        })}
      </DropdownMenu>
    </ButtonDropdown>
  )
}

export default DateRangeQuickSelector
