import React from 'react'
import { Button } from 'reactstrap'

import { useAuth } from '../hooks/useAuth'

const Login: React.FC = () => {
  const { login } = useAuth()
  return (
    <div className='vh-100 w-100 d-flex'>
      <Button className='w-50 m-auto' onClick={() => login()}>Login</Button>
    </div>
  )
}

export default Login
