import React, { useMemo } from 'react'
import { Row, Col, Input } from 'reactstrap'
import Actions from './Actions'
import S3Attachment from './S3Attachment'
import NewAttachment from './NewAttachment'
import { AttachmentState } from '../../../hooks/useAttachments/attachmentReducer'
import { AttachmentMethods } from '../../../hooks/useAttachments'
import { AttachmentSubtype } from '@imcva/imc-hris-common/types'

interface AttachmentProps {
  attachment: AttachmentState['attachments'][0]
  methods: AttachmentMethods
  index: number
  edit: boolean
  subtypes: Array<AttachmentSubtype>
}

const Attachment: React.FC<AttachmentProps> = (props) => {
  const { attachment, methods, index, edit } = props

  const subtypeName = useMemo(() => {
    const subtype = props.subtypes.find(s => s._id === attachment.subtype)
    return subtype?.name
  }, [attachment.subtype, props.subtypes])

  const onSubtypeChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const value = e?.target.value
    methods.onSubtypeChange(index, value)
  }

  return (
    <Row className='py-2 mx-1 border-bottom align-items-center'>
      <Col>
        { "signed" in attachment
          ? ( 
            <S3Attachment
              attachment={attachment}
            />
          ) : (
            <NewAttachment
              attachment={attachment}
              onFileChange={methods.onFileChange}
              index={index}
            />
          )
        }
      </Col>
        {edit
          ? (
            <>
              {props.subtypes.length > 0
                ? (
                  <Col sm={3}>
                    <Input
                      name="subtype"
                      type="select"
                      onChange={onSubtypeChange}
                      value={attachment.subtype}
                      disabled={"delete" in attachment && attachment.delete}
                    >
                      <option value=''>Select a Subtype</option>
                      {props.subtypes.map((subtype) => {
                        return <option value={subtype._id}>{subtype.name}</option>
                      })}
                    </Input>
                  </Col>
                ) : null
              }
              <Col sm={1}>
                <Actions
                  deleted={"delete" in attachment && attachment.delete }
                  del={methods.del}
                  undel={methods.undel}
                  index={index}
                />
              </Col>
            </>
          )
          : (
            <>
              {props.subtypes.length > 0
                ? (
                  <Col sm={3} className='font-weight-normal'>
                    {subtypeName}
                  </Col>
                ) : null
              }
            </>
          )
        }
    </Row>
  )
}

export default Attachment
