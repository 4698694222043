import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faTrashRestore} from '@fortawesome/free-solid-svg-icons'
import { AttachmentMethods } from '../../../hooks/useAttachments'

interface ActionsProps {
  del: AttachmentMethods['del']
  deleted?: boolean
  index: number,
  undel: AttachmentMethods['undel']
}

const Actions: React.FC<ActionsProps> = (props) => {
  const { index, undel, del, deleted } = props
  return (
    <small className='px-1 pb-2 text-center'>
      {deleted
        ? (
          <FontAwesomeIcon
            onClick={async () => {
              undel(index)
            }}
            title='Restore'
            className='text-muted'
            icon={faTrashRestore}
            style={{ cursor: 'pointer' }}
          />
        ) : (
          <FontAwesomeIcon
            onClick={async () => {
              del(index)
            }}
            title='Delete'
            icon={faTrash}
            style={{ cursor: 'pointer' }}
          />
        )
      }
    </small>
  )
}

export default Actions
