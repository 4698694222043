import React, { useState } from 'react'
import {
  Button,
  Collapse,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  NavItem,
  NavLink,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  UncontrolledDropdown
} from 'reactstrap'
import { useAuth } from './hooks/useAuth'
import { Link } from 'react-router-dom'

/**
 *  Site Header - Render menu and login / logout buttons
 */
const Header: React.FC = () => {
  const [isOpen, setOpen] = useState(false)
  const { user, login, logout, checkPermissions } = useAuth()

  const env = process.env.REACT_APP_ENV
  let colors = {}
  switch(env) {
    case 'dev':
      colors = {
        color: 'warning',
        light: true
      }
      break;
    case 'local':
      colors = {
        color: 'danger',
        light: true
      }
      break;
    default:
      colors = {
        color: 'dark',
        dark: true
      }
      break;
  }

  return (
    <Navbar {...colors} fixed='top' expand='md'>
      <NavbarBrand tag={Link} to='/'>
        <img alt='IMC Logo' src={process.env.PUBLIC_URL + '/favicon-32x32.png'} width='30' height='30' className="d-inline-block align-top mr-1" />   
        Information System
      </NavbarBrand>
      <NavbarToggler onClick={() => setOpen(!isOpen)} />
      <Collapse isOpen={isOpen} navbar>
        <Nav navbar>
          {user && checkPermissions('CAT')
            ? (
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                  Recruitment
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem tag={Link} to='/cat/positions'>
                    Positions
                  </DropdownItem>
                  <DropdownItem tag={Link} to={`/cat/distributions`}>
                    Distributions
                  </DropdownItem>
                  <DropdownItem tag={Link} to={`/cat/candidates`}>
                    Candidates
                  </DropdownItem>
                  { checkPermissions('CAT_REPORT')
                    ? (
                      <>
                        <DropdownItem divider />
                        <DropdownItem tag={Link} to={`/cat/reports/overview`}>
                          Overview Report
                        </DropdownItem>
                        <DropdownItem tag={Link} to={`/cat/reports/metrics`}>
                          Distribution Metrics Report
                        </DropdownItem>
                      </>
                    ) : null}
                </DropdownMenu>
              </UncontrolledDropdown>
            ) : null
          }
          { user && checkPermissions('JOB_DESC_DB')
            ? (
              <NavItem>
                <NavLink tag={Link} to='/jobdescriptions'>Job Descriptions</NavLink>
              </NavItem>
            ) : null
            }
          { user && checkPermissions('MANAGE_LOOKUPS')
            ? (
              <NavItem>
                <NavLink tag={Link} to='/lookups'>Manage Lookups</NavLink>
              </NavItem>
            ) : null
            }
        </Nav>
        <div className='d-inline ml-auto'>
          {user
            ? (
              <>
                { user.attributes && user.profile && user.profile.role 
                  ? (
                    <span className="navbar-text mr-3">
                      {user.attributes.name} ({user.profile.role.title})
                    </span>
                  ) : null
                }
                <Button onClick={() => logout()}>Logout</Button>
              </>
            ) : (
              <Button onClick={() => login()}>Login</Button>
            )
          }
        </div>
      </Collapse>
    </Navbar>
  )
}

export default Header
