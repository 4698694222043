import React from 'react'
import { CustomInput, Progress } from 'reactstrap'
import { AttachmentMethods } from '../../../hooks/useAttachments'
import { NewAttachment as NewAttachmentType } from '../../../hooks/useAttachments/attachmentReducer'

interface NewAttachmentProps {
  attachment: NewAttachmentType
  onFileChange: AttachmentMethods['onFileChange']
  index: number
}

const NewAttachment: React.FC<NewAttachmentProps> = (props) => {
  const { attachment, onFileChange, index } = props
  const { progress, filename, file } = attachment

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.currentTarget.files?.[0]
    onFileChange(index, file)
  }

  const maxFileSize = 100 * 1024 * 1024

  return (
    <>
      {progress !== undefined
        ? (
          <Progress
            animated
            striped
            value={progress}
          >
            {filename}: {Math.round(progress)}%
          </Progress>
        ) : (
          <>
            <CustomInput
              type="file"
              id="file"
              name="file"
              label={'Select A File'}
              invalid={file && file.size > maxFileSize}
              onChange={onChange}
            />
            { file && file.size > maxFileSize
              ? (
                <small className='text-danger'>File cannot exceed 100Mb!</small>
              )
              : null
            }
          </>
        )
      }
    </>
  )
}

export default NewAttachment
