import React, { useMemo, useState, useEffect } from 'react'
import { Row, Col } from 'reactstrap';
import { FormikProps, FormikHelpers } from 'formik';
import { Formik } from '@imcva/formik-reactstrap-widgets'
import { API } from 'aws-amplify'
import { useHistory, useParams } from 'react-router-dom';
import Distribution from '@imcva/imc-hris-common/types/distribution'
import Position from '@imcva/imc-hris-common/types/position';

import useItem from '../../../../hooks/useItem'
import useAttachments from '../../../../hooks/useAttachments';
import useItems from '../../../../hooks/useItems'
import useTitle from '../../../../hooks/useTitle';
import useLookups, { getLookupText } from '../../../../hooks/useLookups';
import { useAlerts } from '../../../../hooks/useAlerts';
import { useAuth } from '../../../../hooks/useAuth';

import Loading from '../../../../components/Loading';
import Authorized from '../../../../components/Authorized';

import { Lookups } from './lookups.d'
import useContractPermissions from '../../useContractPermissions'

import Form from './Form'

interface FormProps {
  plaintext?: boolean
}

const DistributionForm: React.FC<FormProps> = (props) => {
  const { plaintext } = props
  const { id } = useParams<{ id?: string }>()
  const [item, setItem, itemLoading, removeItem] = useItem<Distribution>(id ? `/cat/distributions/${id}` : undefined)
  const [positions, , positionsLoading] = useItems<Position>('/cat/positions')
  const [saving, setSaving] = useState(false)
  const { addAlert } = useAlerts()
  const history = useHistory()
  const { checkPermissions } = useAuth()
  const uploadData = useMemo(() => ({
    link: id,
    type: '605897f29c5e6a11b0083ac1',
    model: 'Distribution',
  }), [id])
  const [ attachmentsState, attachmentsMethods ] = useAttachments(uploadData)

  useEffect(() => {
    if(
      uploadData.type !== undefined &&
      uploadData.link !== undefined
    ) {
      const get = attachmentsMethods.get
      get()
    }
  }, [uploadData, attachmentsMethods.get])

  const [lookups, , loadingLookups, select] = useLookups<Lookups>([
    'Distribution_Category',
    'Distribution_Subcategory'
  ])
  const getTitle = () => {
    if (id) {
      if (item?.subcategory && lookups?.Distribution_Subcategory) {
        return getLookupText(lookups?.Distribution_Subcategory, item.subcategory)?.name 
      } else {
        return 'Editing Distribution'
      }
    } else {
      return 'Add Distribution'
    }
  }
  useTitle(getTitle())

  const createPositionOptions = useMemo(() => {
    const options = positions.map(position => {
      return {
        text: `(${position.id}) ${position.title}`,
        value: position._id
      }
    })
    return options
  }, [positions])

  const contract = useMemo(() => {
    return positions.find(p => p._id === item?.position)?.contract
  }, [positions, item])

  const [ contractAuth, loadingContractAuth ]= useContractPermissions(contract)

  const saveItem = async (values: Partial<Distribution<"normal">>, formik: FormikHelpers<Partial<Distribution<"normal">>>) => {
    try {
      setSaving(true)
      if(!attachmentsState.canSave) {
        throw new Error('Invalid attachment. Please try again!')
      }
      let url = '/cat/distributions'
      url = id ? url + `/${id}` : url
      const results = await API.post('hris', url, {
        body: values
      })
      if (results) {
        const item = results.item
        if(item._id && attachmentsState.canSave) {
          await attachmentsMethods.save({ link: item._id })
        }
        setItem(item)
        formik.resetForm()
        let url = '/cat/distributions'
        if (item.position) {
          url = `/cat/positions/${item.position}/distributions`
        }
        history.push(url)
      }
    } catch (error) {
      addAlert(`Could not save item! (${error.message})`)
    } finally {
      setSaving(false)
    }
  }

  const remove = async () => {
    const removed = await removeItem()
    if (removed && removed.success) {
      let url = '/cat/distributions'
      if (item?.position) {
        url = `/cat/positions/${item.position}/distributions`
      }
      history.push(url)
    }
  }

  const authorized = useMemo(() => {
    return plaintext ? !!(contractAuth || checkPermissions('CAT_VIEW')) : !!checkPermissions('CAT_EDIT')
  }, [plaintext, checkPermissions, contractAuth])

  return (
    <Loading loading={itemLoading || positionsLoading || loadingLookups || loadingContractAuth}>
      <Authorized authorized={authorized}>
        <Row className='mb-5'>
          <Col className='mx-auto font-weight-bold' xl={6} lg={8} md={10} sm={12}>
            <Formik
              onSubmit={(values, formik) => saveItem(values, formik)}
              initialValues={item || {}}
              enableReinitialize
              plaintext={plaintext}
              render={(props: FormikProps<Partial<Distribution>>) => {
                const dirty = props.dirty || attachmentsState.dirty
                return (
                  <Form 
                    attachments={{
                      attachmentsState,
                      attachmentsMethods
                    }}
                    createPositionOptions={createPositionOptions}
                    dirty={dirty}
                    formik={props}
                    id={id}
                    lookups={lookups}
                    options={select}
                    plaintext={plaintext}
                    remove={remove}
                    saving={saving}
                  />
                )
              }}
            />
          </Col>
        </Row>
      </Authorized>
    </Loading>
  )
}

export default DistributionForm
