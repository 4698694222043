import React from 'react'
import { Link, RouteComponentProps, useParams } from 'react-router-dom'
import { Row, Col, Button } from 'reactstrap'
import { TableContext, Table, GlobalSearch, globalSearchFilter, useGlobalFilter } from '@imcva/reactstrap-table'
import { useSortBy, useColumnOrder } from 'react-table'

import useItems from '../../../../hooks/useItems'
import { useAuth } from '../../../../hooks/useAuth'
import ColumnControl from './ColumnControl'
import columns from './columns'
import Loading from '../../../../components/Loading'
import { ExtendedCandidate } from './types'

const List: React.FC<RouteComponentProps> = (props) => {
  const { position, distribution } = useParams<{ position?: string, distribution?: string }>()
  let url = '/cat/candidates'
  let addCandidateQueryString = new URLSearchParams()
  if(position) {
    url = url + `/position/${position}`
    addCandidateQueryString.append('position', position)
  }
  if(distribution) {
    url = url + `/distribution/${distribution}`
    addCandidateQueryString.append('distribution', distribution)
  }
  const [ items, , loading ] = useItems<ExtendedCandidate>(url)
  const { checkPermissions } = useAuth()

  return (
    <TableContext<ExtendedCandidate> 
      options={{ 
        columns: columns,
        data: items,
        initialState: {
          hiddenColumns: [
            'distribution_category',
            'rejection_date',
            'received_on',
            'resume_sent',
            'resume_reviewed',
            'resume_reviewed_by',
            'round_1',
            'round_2',
            'round_3',
            'nda',
            'createdAt',
            'updatedAt'
          ]
        },
        globalFilter: globalSearchFilter<ExtendedCandidate>(columns)
      }}

      storageKey='cat-candidate-list'
      plugins={[useGlobalFilter, useSortBy, useColumnOrder]}
    >
      <Row className='mb-3'>
        <Col>
          {checkPermissions('CAT_EDIT')
            ? (
              <Button tag={Link} to={`/cat/candidates/new?${addCandidateQueryString.toString()}`}>New Candidate</Button>
            ) : null
          }
        </Col>
        <Col>
          <Row>
            <Col sm='auto' className='px-1'>
              <ColumnControl />
            </Col>
            <Col className='px-1'>
              <GlobalSearch />
            </Col>
          </Row>
        </Col>
      </Row>
      <Loading loading={loading}>
        <Table responsive stickyHeader responsiveHeight='calc(-125px + 100vh)' />
      </Loading>
    </TableContext>
  )
}

export default List
