import React from 'react'
import { Link, RouteComponentProps, useParams } from 'react-router-dom'
import { Row, Col, Button } from 'reactstrap'
import { TableContext, Table, GlobalSearch, globalSearchFilter, useGlobalFilter } from '@imcva/reactstrap-table'
import { useSortBy, useColumnOrder } from 'react-table'

import useItems from '../../../../hooks/useItems'
import ColumnControl from './ColumnControl'
import columns from './columns'
import Loading from '../../../../components/Loading'
import Distribution from '@imcva/imc-hris-common/types/distribution'
import { useAuth } from '../../../../hooks/useAuth'

const List: React.FC<RouteComponentProps> = (props) => {
  const { position } = useParams<{ position?: string | undefined }>()
  const positionUrl = position ? `/position/${position}` : undefined
  const [ items, , loading ] = useItems<Distribution<'populate'>>(`/cat/distributions${positionUrl || ''}`)
  const { checkPermissions } = useAuth()

  return (
    <TableContext<Distribution<'populate'>> 
      options={{ 
        columns: columns,
        data: items,
        initialState: {
          hiddenColumns: [
            'category',
            'notes',
            'createdAt',
            'updatedAt'
          ]
        },
        globalFilter: globalSearchFilter<Distribution<'populate'>>(columns)
      }}
      storageKey='cat-distribution-list'
      plugins={[useGlobalFilter, useSortBy, useColumnOrder]}
    >
      <Row className='mb-3'>
        <Col>
          {checkPermissions('CAT_EDIT')
            ? (
              <>
                <Button tag={Link} to={`/cat/distributions/new${position ? `?position=${position}` : ''}`}>New Distribution</Button>
                {' '}
                <Button tag={Link} to={`/cat/distributions/bulk${position ? `?position=${position}` : ''}`}>Bulk Distributions</Button>
              </>
            ) : null
          }
        </Col>
        <Col>
          <Row>
            <Col sm='auto' className='px-1'>
              <ColumnControl />
            </Col>
            <Col className='px-1'>
              <GlobalSearch />
            </Col>
          </Row>
        </Col>
      </Row>
      <Loading loading={loading}>
        <Table responsive stickyHeader responsiveHeight='calc(-125px + 100vh)' />
      </Loading>
    </TableContext>
  )
}

export default List
