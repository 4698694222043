import React, { useState, useEffect } from 'react'
import { Row, Col } from 'reactstrap'
import { useRouteMatch, useHistory } from 'react-router-dom'
import { ILookupConfig } from '@imcva/imc-hris-common/types'
import { Checkbox, Radio, DatePicker, Input, Select } from '@imcva/formik-reactstrap-widgets'
import { Formik, FormikProps } from 'formik'
import SaveButton from './SaveButton'

import { OptionFormProps } from './types'
import LoadingAuth from './LoadingAuth'

interface ListOptionRouteParams {
  lookup: string
  option: string
}

const OptionForm: React.FC<OptionFormProps> = (props) => {
  const { getOptions, checkPermissions } = props
  const { params, url } = useRouteMatch<ListOptionRouteParams>()
  const history = useHistory()
  const [item, setItem] = useState<{ [key: string]: any } | null>(null)
  const [config, setConfig] = useState<ILookupConfig | undefined>()
  const [saving, setSaving] = useState(false)

  useEffect(() => {
    const getConfig = async () => {
      const result = await getOptions(params.lookup)
      const option = result.items.find(opt => opt._id === params.option)
      setConfig(result.config)
      setItem(option || null)
    }
    getConfig()
  }, [url, params.lookup, getOptions, params.option])

  const submit = async (values: { [key: string]: any }) => {
    try {
      if (config) {
        setSaving(true)
        let results
        if (item) {
          results = await props.editOption(config._id, params.option, values)
        } else {
          results = await props.addOption(config._id, values)
        }
        if (results) {
          history.push({
            pathname: `/lookups/${config._id}`,
          })
        }
      } else {
        throw new Error('The lookup config must be loaded first.')
      }
    } catch (error) {
      console.log(error)
    } finally {
      setSaving(false)
    }
  }

  const FieldComponents = {
    Checkbox,
    Radio,
    DatePicker,
    Input,
    Select
  }

  return (
    <LoadingAuth loading={!config} checkPermissions={checkPermissions} config={config}>
      {config && config.fields
        ? (
          <Formik
            onSubmit={submit}
            enableReinitialize
            initialValues={item || {}}
            render={(formik: FormikProps<{ [key: string]: any }>) => (
              <Row>
                <Col xl='4' lg='6' className='m-auto'>
                  <h3>{item ? 'Edit' : 'Add'} {config.name || ''} Option</h3>
                  <hr />
                  {config.fields.map((field, index) => {
                    if (field.component) {
                      const Component = FieldComponents[field.component.type] || Input
                      return (
                        <Component
                          key={index}
                          name={field.key}
                          label={field.title}
                          {...field.component.props}
                        />
                      )
                    }
                    return null
                  })}
                  <SaveButton
                    saving={saving}
                    block
                    dirty={formik.dirty}
                    onClick={() => {
                      formik.submitForm()
                    }}
                  />
                </Col>
              </Row>
            )}
          />
        ) : (
          <span>No fields set for this lookup.</span>
        )}
    </LoadingAuth>
  )
}



export default OptionForm
