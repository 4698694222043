import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFile } from '@fortawesome/free-solid-svg-icons'

interface AttachmentCountProps {
  value: number
}

const AttachmentCount: React.FC<AttachmentCountProps> = (props) => {
  if(!isNaN(Number(props.value)) && props.value > 0) {
    const style = {
      fontWeight: 900,
      fontSize: props.value < 100  ? '1rem' : '0.70rem',
      top: '62%'
    }
    const value = props.value < 100 ? props.value : '99+'
    return (
      <>
        <span className="fa-layers fa-fw fa-2x" style={{ zIndex: -1 }}> 
          <FontAwesomeIcon
            title='Attachment'
            icon={faFile}
            className='text-muted mx-2'
          />
          <span className="fa-layers-text fa-inverse h5" data-fa-transform="shrink-20 down-3" style={style}>{value}</span>
        </span>
      </>
    )
  }
  return null
}

export default AttachmentCount
