import React from 'react'
import { Row, Col, Button } from 'reactstrap'
import Attachment from './Attachment'
import Loading from '../Loading'
import { AttachmentState } from '../../hooks/useAttachments/attachmentReducer'
import { AttachmentMethods } from '../../hooks/useAttachments'

interface AttachmentsProps {
  title: string
  state: AttachmentState
  methods: AttachmentMethods
  edit: boolean
}

const Attachments: React.FC<AttachmentsProps>  = (props) => {
  const { state, methods, edit } = props

  return (
    <>
      <h4>
        {props.title}
        {edit
          ? (
            <Button className='float-right' size='sm' onClick={() => methods.add()}>Add</Button>
          )
          : null
        }
      </h4>
      <div className='ml-n1 mb-3 mx-1 border-top'>
        <Loading loading={state.loading}>
          {state.attachments.map((attachment, index) => {
            const id = "_id" in attachment ? attachment._id : attachment._tempid
            return (
              <Attachment
                attachment={attachment}
                subtypes={state.subtypes}
                methods={methods}
                index={index}
                key={id}
                edit={edit}
              />
            )
          })}
          {state.attachments.length <= 0
            ? (
              <Row className='py-2 mx-1 border-bottom font-weight-normal'>
                <Col>No attachments found.</Col>
              </Row>
            )
            : null
          }
        </Loading>
      </div>
    </>
  )
}

export default Attachments
