import React, { ReactElement } from 'react'
import { Link } from 'react-router-dom'
import { DateFormat } from '../../../../components/TableHelpers'
import { Column as ReactTableColumn, UseSortByColumnOptions, CellProps } from 'react-table'
import Distribution from '@imcva/imc-hris-common/types/distribution'
import AttachmentCount from '../../../../components/AttachmentCount'

type Cell<V = any> = CellProps<Distribution<'populate'>, V>

interface ExtendedOptions {
  className?: string
  Cell?: (props: Cell<string>) => ReactElement | ReactElement[] | string | number | null 
}

type Column = ReactTableColumn<Distribution<'populate'>> & UseSortByColumnOptions<Distribution<'populate'>> & ExtendedOptions

const columns: Column[] = [
  {
    id: 'attachments',
    Header: 'Attachments',
    accessor: 'attachment_count',
    className: 'text-center align-middle',
    Cell: (cell: any) => {
      return <AttachmentCount value={cell.value} />
    }
  },
  {
    id: 'subcategory',
    Header: 'Subcategory',
    accessor: d => d.subcategory?.name,
    Cell: ({ row: { original }, value }) => {
      if (original._id) {
        return (
          <Link to={`/cat/distributions/${original._id}`}>{value}</Link>
        )
      }
      return value
    }
  },
  {
    id: 'category',
    Header: 'Category',
    accessor: d => d.category?.name,
  },
  {
    id: 'title',
    Header: 'Position',
    className: 'text-left pl-2',
    accessor: d => {
      const { position } = d
      if (position) {
        return `(${position.id}) ${position.title}`
      }
      return null
    }
  },
  {
    Header: 'Date Posted / Sent',
    accessor: 'date_posted',
    Cell: ({ cell: { value } }) => (
      <DateFormat value={value} format='P' />
    )
  },
  {
    id: 'candidates',
    Header: 'Candidates',
    accessor: (d) => {
      const { position } = d
      const count = d.candidate_count
      if (position) {
        return (
          <>
            <Link to={`/cat/positions/${position._id}/distributions/${d._id}/candidates`}>
              Candidates
            </Link>
            {count ? ` (${count}) ` : null}
          </>
        )
      } else {
        return 'Candidates'
      }
    }
  },
  {
    Header: 'Created On',
    accessor: 'createdAt',
    Cell: ({ cell: { value }}) => (
      <DateFormat value={value} />
    ),
  },
  {
    Header: 'Last Updated On',
    accessor: 'updatedAt',
    Cell: ({ cell: { value }}) => (
      <DateFormat value={value} />
    ),
  }
]

export default columns
