import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom'

import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import configureAmplify from './amplify'
import configureAxios from './axios'

import 'bootstrap/dist/css/bootstrap.min.css'
import '@imcva/formik-reactstrap-widgets/dist/styles.css'

configureAmplify()
configureAxios()

ReactDOM.render(
  <BrowserRouter basename={process.env.REACT_APP_PUBLIC_URL || ''}>
    <App />
  </BrowserRouter>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
