import React from 'react'
import { Button, Modal as ReactstrapModal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'

const Modal = (props) => {
  return (
    <ReactstrapModal isOpen={props.open} toggle={props.toggle}>
      <ModalHeader toggle={props.toggle}>Remove <b>{props.title}</b></ModalHeader>
      <ModalBody>
        Are you sure you want to remove
        {props.title
          ? <>{' '}<b>{props.title}</b></>
          : 'this item'
        } 
        <span>?</span>
      </ModalBody>
      <ModalFooter>
        <Button color="danger" onClick={props.onConfirm}>Remove</Button>{' '}
        <Button color="secondary" onClick={props.toggle}>Cancel</Button>
      </ModalFooter>
    </ReactstrapModal>
  )
}

export default Modal
