import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import { withRouter, RouteComponentProps } from 'react-router-dom'

import Routes from './routes'
import Header from './Header'
import { AuthProvider } from './hooks/useAuth'

import { AlertProvider, Alerts } from './hooks/useAlerts'

const App: React.FC<RouteComponentProps> = (props) => {
  return (
    <Container fluid>
      <AlertProvider location={props.location}>
        <AuthProvider>
            <Header />
            <Row>
              <Col>
                <Alerts />
                <Routes />
              </Col>
            </Row>
        </AuthProvider>
      </AlertProvider>
    </Container>
  )
}

export default withRouter(App)
