import React from 'react'
import CellBackground from './CellBackgroup';

interface NumberRangeProps {
  min?: number
  max?: number
  highlightNumber?: number
  render?: React.FC<{ value?: number }>
}

interface RenderProps {
  render?: NumberRangeProps['render']
  value?: number
}

const Render: React.FC<RenderProps> = (props) => {
  const R = props.render
  if(R) {
    return (
      <R value={props.value} />
    )
  } else {
    return <>{props.value}</>
  }
}

const NumberRange: React.FC<NumberRangeProps> = (props) => {
  const { min, max, highlightNumber, render } = props
  let display = <Render render={render} value={min} />
  if (min && max && (min > max || min < max)) {
    display = (<><Render render={render} value={min} /> - <Render render={render} value={max} /></>)
  }
  if(highlightNumber && max && highlightNumber < max) {
    return (
      <CellBackground color='warning'>{display}</CellBackground>
    )
  }
  return (
    <>
      {display}
    </>
  )
}

export default NumberRange