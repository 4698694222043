import React from 'react'
import { Button, Spinner } from 'reactstrap'

const SaveButton = (props) => (
  <Button
    className={props.className}
    block={props.block}
    color={props.color || 'primary'}
    disabled={props.saving || !props.dirty}
    type='submit'
  >
    {props.text || 'Save'} {props.saving ? <Spinner className='ml-1' size="sm" color="dark" /> : null}
  </Button>
)

export default SaveButton
