import React from 'react'
import { Row, Col, Form, Label, FormGroup, Input, Button } from 'reactstrap'
import DateRangeQuickSelector from './DateRangeQuickSelector'
import Info from './Info'

export interface Dates {
  from: string
  to: string
}

interface HeaderProps {
  dates: Dates
  onDateChange: (key: keyof Dates) => (e: React.ChangeEvent<HTMLInputElement>) => void 
  onQuickSelectorChange: (opt: { label: string, to: Date, from: Date}) => void
  onUpdate: () => void
  highlight: boolean
  toggleHighlight: () => void
}

const Header: React.FC<HeaderProps> = (props) => {
  const { 
    dates,
    onDateChange,
    onQuickSelectorChange,
    onUpdate,
    highlight,
    toggleHighlight
  } = props
  return (
    <>
      <Row className='mb-0'>
        <Col>
          <h2>Distribution Metrics Report</h2>
        </Col>
        <Col xl='auto' className='px-4 d-flex align-items-center'>
          <Info />
        </Col>
      </Row>
      <hr className='mt-0' />
      <Row className='mb-3'>
        <Col className='background-light'>
          <small>
            <Form inline>
              <FormGroup className='mr-2'>
                <Label for='from' className='mr-1'>From:</Label>
                <Input
                  type='date'
                  bsSize='sm'
                  name='from'
                  id='from'
                  value={dates.from}
                  onChange={onDateChange('from')}
                />
              </FormGroup>
              <FormGroup className='mx-1'>
                <Label for='to' className='mr-1'>To:</Label>
                <Input
                  type='date'
                  bsSize='sm'
                  name='to'
                  id='to'
                  value={dates.to}
                  onChange={onDateChange('to')}
                />
              </FormGroup>
              <span className='mx-2'>
                <DateRangeQuickSelector onChange={onQuickSelectorChange} />
              </span>
              <Button size='sm' onClick={onUpdate}>Update</Button>
            </Form>
          </small>
        </Col>
        <Col xl="auto">
          <Button onClick={toggleHighlight} active={highlight} size='sm' outline>
            Highlight {highlight ? 'On' : 'Off'}
          </Button>
        </Col>
      </Row>
    </>
  )
}

export default Header
