import { useEffect, useRef } from "react"
import { IUser, useAuth } from "./useAuth"
import isEqual from 'lodash/isEqual'
import { API } from 'aws-amplify'

const getSize = () => ({ 
  width: document.documentElement.clientWidth,
  height: document.documentElement.clientHeight,
})

interface AnalyticsData {
  location: Record<string, string>
  user: IUser | undefined
  size: {
    width: number
    height: number
  },
  agent: string
}

const useAnalytics = () => {
  const location = JSON.parse(JSON.stringify(window.location))
  const { user, loading } = useAuth()

  const profile = user?.profile
  const dataRef = useRef<AnalyticsData | undefined>()
  useEffect(() => {
    if(!loading) {
      const data = { 
        location,
        user: profile,
        size: getSize(),
        agent: navigator.userAgent
      }
      if(!isEqual(data, dataRef.current)) {
        API.post('hris', '/analytics', {
          body: data
        })
      }
      dataRef.current = data
    }
  }, [location, profile, loading])
}

export default useAnalytics