import React from 'react'
import { Progress } from 'reactstrap'

import { LoadingAuthProps } from './types'

const LoadingAuth: React.FC<LoadingAuthProps> = (props) => {
  const { loading, checkPermissions, config } = props

  const checkAuth = () => {
    if(config && checkPermissions && checkPermissions(config.permissionKey)) {
      return true
    }
    return false
  }

  if(loading) {
    return (
      <Progress bar animated value="100">Loading...</Progress>
    )
  } else if (!checkAuth()) {
    return (
      <h2>You are not authorized to view this page!</h2>
    )
  } else {
    return (
      <>
        {props.children}
      </>
    )
  }
}

export default LoadingAuth
