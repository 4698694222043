import React from 'react'
import Unauthorized from './Unauthorized'

interface AuthorizedProps {
  authorized: boolean
}

const Authorized: React.FC<AuthorizedProps> = (props) => {
  if(props.authorized) {
    return (
      <>
        {props.children}
      </>
    )
  } else {
    return (
      <>
        <Unauthorized />
      </>
    )
  }
}

export default Authorized
