import { useEffect, useState } from 'react'
import { API } from 'aws-amplify'
import { Cat_Contract } from '@imcva/imc-hris-common/types/lookups'
import { useAuth } from '../../hooks/useAuth'

const useContractPermissions = (contract?: Cat_Contract | string) => {
  const { user } = useAuth()
  const [ authorized, setAuthorized ] = useState<boolean>(false)
  const [ loading, setLoading ] = useState<boolean>(true)


  useEffect(() => {
    const checkAccess = (email: string | undefined, access: string | string[] | undefined) => {
      let accessList = access
      if (typeof accessList == 'string') {
        accessList = accessList.split(';')
      }
      if(Array.isArray(accessList) && email) {
        return accessList.includes(email)
      }
      return false
    }

    const getAccess = async () => {
      setLoading(true)
      if(contract) {
        const email = user?.profile?.email
        if (typeof contract === 'string') {
          const result = await API.get('hris', `/lookups/Cat_Contract/${contract}`, {}) 
          setAuthorized(checkAccess(email, result?.item?.access))
        } else if (contract.access) {
          setAuthorized(checkAccess(email, contract?.access))
        }
      } else {
        setAuthorized(false)
      }
      setLoading(false)
    }
    getAccess()
  }, [contract, user])

  return [ authorized, loading ]
}

export default useContractPermissions