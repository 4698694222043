import React from 'react'
import { Row, Col } from 'reactstrap'
import { Radio, Select, DatePicker } from '@imcva/formik-reactstrap-widgets'
import { Lookups } from './lookups'
import { SelectOption } from '../../../../hooks/useLookups'

interface StatusProps {
  options: Record<keyof Lookups, SelectOption[]> | undefined
  status: string | undefined
  active: boolean
}

const Status: React.FC<StatusProps> = (props) => {
  const createSubstatusOptions = () => {
    if(props.options) {
      return props.options.Candidate_Substatus.filter(substatus => {
        return substatus.status === props.status
      }) 
    }
    return 
  }

  if (props.active) {
    return (
      <>
        <Row>
          <Col md={6}>
            <Select name='status' InsertBlank options={props.options?.Candidate_Status} label='Status' />
          </Col>
          <Col md={6}>
            <Select name='substatus' InsertBlank options={props.options?.Candidate_Substatus} filtered={createSubstatusOptions()} label='Sub Status' />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <DatePicker name='resume.sent' label='Resume Sent' />
          </Col>
          <Col md={6}>
            <DatePicker name='resume.reviewed' label='Resume Reviewed' />
          </Col>
        </Row>
        <Select name='resume.reviewed_by' options={props.options?.Hiring_Manager} label='Reviewed By' InsertBlank />
        <Select name='rank' options={props.options?.Candidate_Rank} label='Rank' InsertBlank />
        <DatePicker name='rejection_date' label='Rejection Date' />
        <DatePicker name='round_1' label='Round 1' />
        <DatePicker name='round_2' label='Round 2' />
        <DatePicker name='round_3' label='Round 3' />
        <Radio
          button
          block
          group
          name='reapply'
          label='Can candididate reapply?'
          color='primary'
          className='text-left'
          outline
          options={[
            {text: 'Yes', value: true},
            {text: 'No', value: false}
          ]}
        />
      </>
    )
  } else {
    return null
  }
}

export default Status
