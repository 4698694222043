import { useEffect } from 'react'

const useTitle = (subtitle?: string, title: string = 'HRIS') => {
  useEffect(() => {
    const sub = subtitle ? ` | ${subtitle}` : ''
    document.title = `${title}${sub}`
  }, [subtitle, title])
}

export default useTitle
