import React, { useEffect, useState } from 'react'
import { Row, Col, Progress } from 'reactstrap'
import { API } from 'aws-amplify'

import useTitle from '../../../hooks/useTitle';
import { useAlerts } from '../../../hooks/useAlerts';

const ReportPage: React.FC = (props) => {
  const [ loading, setLoading ] = useState(true)
  const [ doc, setDoc ] = useState<string | undefined>()
  const { addAlert } = useAlerts()
  useTitle('Position Overview Report')
  useEffect(() => {
    const loadReport = async () => {
      setLoading(true)
      const pdf = await API.get('hris', '/cat/reports/overview', {
        responseType: 'blob',
        headers: {
          'Accept': 'application/pdf'
        }
      })
      const file = new FileReader()
      file.onload = function() {
        if(typeof file.result === 'string') {
          setDoc(file.result)
        } else {
          addAlert('Error loading report.')
        }
        setLoading(false)
      }
      file.readAsDataURL(pdf)
    }
    loadReport()
  }, [addAlert])

  return (
    <Row>
      <Col md={9} className='mx-auto'>
        <h2>Position Overview</h2>
        <div className='d-flex justify-content-center'>
          <br />
          {loading
            ? (
              <Progress className='w-100 mx-2' animated value={100}>
                Creating Report...
              </Progress>
            )
            : (
              <iframe src={doc} title='Position Overview Report' style={{width: '100%', height: 'calc(100vh - 150px)'}} />
            )
          }
        </div>
      </Col>
    </Row>
  )
}

export default ReportPage
