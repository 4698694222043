import React from 'react'
import { isValid, formatDistance } from 'date-fns'

interface UploadDateProps { 
  date?: Date | string
}

const UploadDate: React.FC<UploadDateProps> = (props) => { 
  if(props.date !== undefined ) {
    const date = new Date(props.date)
    if (isValid(date)) {
      return (
        <span className='mx-1 text-muted'>
          <small>
            uploaded {formatDistance(date, new Date(), { addSuffix: true })}
          </small>
        </span>
      )
    }
  }
  return null
}

export default UploadDate
