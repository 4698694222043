import React from 'react'
import { Route, Switch } from 'react-router-dom'
import NotFound from './NotFound'

const CustomSwitch: React.FC = (props) => {
  return (
    <Switch>
      {props.children}
      <Route>
        <NotFound />
      </Route>
    </Switch>
  )
}

export default CustomSwitch
