import React from 'react'
import { Button, ButtonProps } from 'reactstrap'

import SaveButton, { ISaveButton } from '../SaveButton'
import { useAuth } from '../../hooks/useAuth'

interface FormTitleProps {
  id?: string,
  plaintext?: boolean
  auth?: string,
  titleText: {
    new: string
    edit: string
    save: string 
  }
  actionProps: {
    save: ISaveButton,
    edit: ButtonProps
  }
}

const TitleWrapper: React.FC = (props) => ( 
  <>
    <h2>
      {props.children}
    </h2>
    <hr />
  </>
)

const Title: React.FC<FormTitleProps> = (props) => {
  const { checkPermissions } = useAuth()

  if(!props.id) {
    return (
      <TitleWrapper>
        {props.titleText.new}
        <SaveButton data-id='no-id' className='float-right' {...props.actionProps.save} />
      </TitleWrapper>
    )
  } else if (props.plaintext) {
    return (
      <TitleWrapper>
        {props.titleText.edit}
        {!props.auth || checkPermissions(props.auth)
          ? (
            <Button className='float-right' data-id='plaintext' color='primary' {...props.actionProps.edit}>
              {props.actionProps.edit.text || 'Edit'}
            </Button>
          ) : null}
      </TitleWrapper>
    )
  } else {
    return (
      <TitleWrapper>
        {props.titleText.save}
        <SaveButton data-id='else' className='float-right' {...props.actionProps.save} />
      </TitleWrapper>
    )
  }
}

export default Title
