import API from '@aws-amplify/api'
import React, { useState, useEffect, useCallback } from 'react'
import { Row, Col, Table } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight, faAngleDown } from '@fortawesome/free-solid-svg-icons'
import { format, isBefore, isValid, sub } from 'date-fns'
import { Link } from 'react-router-dom'
import { useAlerts } from '../../../../hooks/useAlerts'
import Loading from '../../../../components/Loading'
import MetricCells from './MetricCells'
import Header, { Dates } from './Header'
import { IMetricCounts, IMetrics } from './types'

interface MetricsProps {

}


interface MetricsResults extends IMetricCounts {
  success: boolean
  metrics: Array<IMetrics>
}

const fixPositionID = (id: number) => {
  if (id) {
    const paddedId = String(id).padStart(4, '0')
    const prefixedId = 'IMC' + paddedId
    return prefixedId
  }
}

const Metrics: React.FC<MetricsProps> = (props) => {
  const [data, setData] = useState<Array<IMetrics>>([])
  const [dates, setDates] = useState<Dates>({
    from: format(sub(new Date(), { years: 1 }), "yyyy-MM-dd"),
    to: format(sub(new Date(), {days: 1}), "yyyy-MM-dd")
  })
  const [highlight, setHighlight] = useState(true)
  const [loading, setLoading] = useState(true)
  const { addAlert, resetAlerts } = useAlerts()
  const toggleHightlight = () => setHighlight(h => !h)

  const getData = useCallback(async (queryDates: Dates) => {
    try {
      resetAlerts()
      const parsedDates  = {
        to: new Date(queryDates.to),
        from: new Date(queryDates.from)
      }
      if(!isValid(parsedDates.to) || !isValid(parsedDates.from)) {
        throw new Error("Invalid dates. Please try again.") 
      }
      if(!isBefore(parsedDates.from, parsedDates.to)) {
        throw new Error("Invalid date range. Please try again.") 
      }
      const dateStrings  = {
        to: parsedDates.to.toISOString(),
        from: parsedDates.from.toISOString()
      }
      const url = `/cat/reports/metrics?to=${dateStrings.to}&from=${dateStrings.from}`
      setLoading(true)
      const results = await API.get('hris', url, {}) as MetricsResults
      if (results.metrics) {
        const metrics = results.metrics.map(d => {
          d.hideSubmetrics = true
          return d
        })
        setData(metrics)
      }
      setLoading(false)
    } catch (error) {
      let message = 'Unknown Error'
      if (error instanceof Error) message = error.message
      addAlert(message)
    }
  }, [setLoading, setData, addAlert, resetAlerts])

  useEffect(() => {
    getData(dates)
    // Only run this on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onHide = (index: number) => {
    setData(d => {
      if (d && d[index]) {
        const newData = [...d]
        newData[index] = { ...newData[index], hideSubmetrics: !newData[index].hideSubmetrics }
        return newData
      }
      return d
    })
  }

  const onDateChange = (key: keyof Dates) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setDates(d => {
      const newDates = { ...d }
      newDates[key] = value
      return newDates
    }) 
  }

  const quickSetDates = (opt: { label: string, to: Date, from: Date}) => {
    const newDates = {
      to: format(opt.to, 'yyyy-MM-dd'),
      from: format(opt.from, 'yyyy-MM-dd')
    }
    setDates(newDates)
    getData(newDates)
  }

  const openSection = data.find(d => d.hideSubmetrics !== true) ? true : false

  return (
    <>
      <Header
        dates={dates}
        onDateChange={onDateChange}
        onQuickSelectorChange={quickSetDates}
        onUpdate={() => getData(dates)}
        highlight={highlight}
        toggleHighlight={toggleHightlight}
      />
      <Row>
        <Col>
          <Loading loading={loading}>
            <div className="table-responsive" style={{"maxHeight": "calc(-200px + 100vh)"}}>
              <Table size='sm' bordered hover style={{height: '100%'}}>
                <thead className="thead-light sticky-header">
                  <tr>
                    <th>Distribution</th>
                    <th>Category</th>
                    <th>Position</th>
                    <th>Submitted</th>
                    <th>Round 1</th>
                    <th>Round 2</th>
                    <th>Round 3</th>
                    <th>Withdrew</th>
                    <th>Filled</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((row, index) => (
                    <>
                      <tr key={index} className={row.hideSubmetrics ? '' : 'table-secondary'} style={{height: '100%'}}>
                        <td onClick={() => onHide(index)} >
                          <FontAwesomeIcon
                            icon={row.hideSubmetrics ? faAngleRight : faAngleDown}
                            className='mx-2'
                          />
                          {row.subcategory}
                        </td>
                        <td>{row.category}</td>
                        <td>{row.positionCount}</td>
                        <td>{row.submitted}</td>
                        <MetricCells
                          data={row}
                          colorOverride={openSection ? 'secondary' : undefined }
                          backgroundColor={highlight}
                        />
                      </tr>
                      {row.hideSubmetrics !== true
                        ? <>
                          {row.submetrics.map((subrow, subindex) => (
                            <tr key={`${index}-${subindex}`} style={{height: '100%'}}>
                              <td colSpan={3} className='pl-5'>
                                <Link to={`/cat/positions/${subrow.position._id}`}>
                                  ({fixPositionID(subrow.position._id)}) {subrow.position.title}
                                </Link>
                              </td>
                              <td>{subrow.submitted}</td>
                              <MetricCells data={subrow} backgroundColor={highlight} />
                            </tr>
                          ))}
                        </>
                        : null
                      }
                    </>
                  ))}
                </tbody>
              </Table>
            </div>
          </Loading>
        </Col>
      </Row>
    </>
  )
}

export default Metrics
