import React, { useState, useEffect } from 'react'
import { useRouteMatch, useHistory } from 'react-router-dom'
import { Formik, FormikProps } from 'formik'
import { Select } from '@imcva/formik-reactstrap-widgets'
import { Col, Row } from 'reactstrap'
import { ILookupConfig } from '@imcva/imc-hris-common/types'

import SaveButton from './SaveButton'
import { ConfigFormProps } from './types'

interface ConfigRouteParams  {
  lookup: string
}

const ConfigForm: React.FC<ConfigFormProps> = (props) => {
  const { editConfig, getOptions } = props
  const { params, url } = useRouteMatch<ConfigRouteParams>()
  const history = useHistory()
  const [ config, setConfig ] = useState<ILookupConfig | undefined>()
  const [ saving, setSaving ] = useState(false) 

  useEffect(() => {
    const getItems = async () => {
      const result = await getOptions(params.lookup)
      setConfig(result.config)
    }
    getItems()
  }, [url, params.lookup, getOptions])

  const submit = async (values: { [key: string]: any }) => {
    try {
      const results = await editConfig(params.lookup, values) 
      if (results) {
          history.push({
            pathname: `/lookups/${params.lookup}`,
          })
      }
    } catch (error) {
      console.log(error)
    } finally {
      setSaving(false)
    }
  }
  

  if (config && config.fields) {
    return (
      <Formik
        onSubmit={submit}
        enableReinitialize
        initialValues={config || {}}
        render={(formik: FormikProps<ILookupConfig>) => (
          <Row>
            <Col xl='4' lg='6' className='m-auto'>
              <h3>Edit Sort for {config.name || ''}</h3>
              <hr />
              <Select 
                label='Sort Column'
                name='sort'
                options={config.fields.map(opt => ({ text: opt.title, value: opt.key }))}
              />
              <Select 
                label='Sort Order'
                name='sortOrder'
                options={[
                  { text: 'Ascending', value: 1 },
                  { text: 'Descending', value: -1 },
                ]}
              />
              <SaveButton
                saving={saving}
                block
                dirty={formik.dirty}
                onClick={() => {
                  formik.submitForm()
                }}
              />
            </Col>
          </Row>
        )}
      />
    )
  } else {
    return (
      <span>Loading...</span>
    )
  }
}

export default ConfigForm
