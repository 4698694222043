import React from 'react'

import './cellBackground.css'

const CellBackground = (props) => {
  const { percentage, color } = props
  return (
    <div className='cellBackground-container'>
      <div className='cellBackground-color' style={{
        ...styles[color],
        width: `${percentage !== undefined ? percentage : 100}%`,
      }}>
      </div>
      <div className='cellBackground-text text-wrap'>
        {props.children}
      </div>
    </div>
  )
}

const styles = {
  'primary': {
    backgroundColor: '#cce5ff',
    borderTopColor: '#007bff'
  },
  'secondary': {
    backgroundColor: '#e2e3e5',
    borderTopColor: '#6c757d'
  },
  'success': {
    backgroundColor: '#d4edda',
    borderTopColor: '#28a745'
  },
  'danger': {
    backgroundColor: '#f8d7da',
    borderTopColor: '#dc3545'
  },
  'warning': {
    backgroundColor: '#fff3cd',
    borderTopColor: '#ffc107'
  },
  'info': {
    backgroundColor: '#d1ecf1',
    borderTopColor: '#17a2b8'
  },
  'light': {
    backgroundColor: '#fefefe',
    borderTopColor: '#d1ecf1'
  },
  'dark': {
    backgroundColor: '#d6d8d9',
    borderTopColor: '#343a40'
  }
}

export default CellBackground