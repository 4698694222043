import React from 'react'
import { useHistory } from 'react-router'
import { useAuth } from '../hooks/useAuth'

interface DefaultRouteRedirectProps {

}

const DefaultRouteRedirect: React.FC<DefaultRouteRedirectProps> = (props) => {
  const history = useHistory()
  const auth = useAuth()
  const page = auth.user?.profile?.defaultPage || auth.user?.profile?.role.defaultPage || '/404'

  history.push(page)

  return null
}

export default DefaultRouteRedirect
