import React, { useState, useRef, useEffect, useMemo, useCallback } from 'react'
import { Row, Col } from 'reactstrap'
import { Form as FormikForm, FormikProps } from 'formik'
import { Input, DatePicker } from '@imcva/formik-reactstrap-widgets'
import { Link, useLocation } from 'react-router-dom'
import Position from '@imcva/imc-hris-common/types/position'
import Distribution from '@imcva/imc-hris-common/types/distribution'
import Candidate from '@imcva/imc-hris-common/types/candidate'
import querystring from 'query-string'

import { getLookupText, SelectOption } from '../../../../hooks/useLookups'

import Details from './Details'
import Exhibit from './Exhibit'
import Nda from './Nda'
import OfferLetter from './OfferLetter'
import Status from './Status'
import Tabs from './Tabs'

import { RemoveButton, SaveButton } from '../../../../components/FormHelpers'

import { Lookups } from './lookups'
import Title from '../../../../components/FormHelpers/Title'
import { useAuth } from '../../../../hooks/useAuth'
import { AttachmentState } from '../../../../hooks/useAttachments/attachmentReducer'
import { AttachmentMethods } from '../../../../hooks/useAttachments'
import Attachments from '../../../../components/Attachments'

interface FormProps extends FormikProps<Partial<Candidate>> {
  plaintext?: boolean
  item?: Candidate
  id?: string,
  remove: () => Promise<void>,
  options: Record<keyof Lookups, SelectOption[]> | undefined
  saving: boolean,
  positions: Position<'populate'>[]
  distributions: Distribution<'populate'>[]
  attachments: {
    attachmentsState: AttachmentState,
    attachmentsMethods: AttachmentMethods
  }
}

const Form: React.FC<FormProps> = (props) => {
  const {
    values,
    setFieldValue,
    id,
    remove,
    positions,
    distributions,
    options,
    plaintext,
    attachments: {
      attachmentsState,
      attachmentsMethods
    }
  } = props
  const dirty = props.dirty || attachmentsState.dirty
  const location = useLocation()
  const { checkPermissions } = useAuth()
  const { position, distribution } = querystring.parse(location.search)
  const [ activeTab, setTab ] = useState('details')

  const typeValue = getLookupText(props.options?.Candidate_Type, values.type)?.text
  const offerletter = typeValue === 'Direct Hire'
  const exhibit = typeValue === 'Temp to Perm' || typeValue === 'Contract to Contract'

  const positionOptions = useMemo(() => {
    const options = positions.map(position => {
      const contract = position.contract ? `[${position.contract.name}]` : ''
      return {
        text: `(${position.id}) ${position.title} ${contract}`,
        value: position._id
      }
    })
    return options
  }, [positions])

  const currentPosition = useMemo(() => {
    const p = positions.find(position => {
      const positionValue = Number(values.position)
      if(!isNaN(positionValue)) {
        return position._id ===  positionValue
      } 
      return false
    })
    return p
  }, [positions, values.position])

  const createDistributionOptions = useCallback((position?: string) => {
    const options = distributions.filter(distribution => {
      if (distribution.position) {
        return Number(distribution.position._id) === Number(position)
      }
      return false 
    }).map(distribution => {
      return {
        text: `${distribution.subcategory.name} (${distribution.category.name})`,
        value: distribution._id
      }
    })
    return options
  }, [distributions])

  const reviewedOnRef = useRef(values.resume ? values.resume.reviewed : undefined)
  useEffect(() => {
    const reviewedOn = values.resume ? values.resume.reviewed : undefined
    const reviewedBy = values.resume ? values.resume.reviewed_by : undefined
    const prevReviewed = reviewedOnRef.current
    if (prevReviewed !== reviewedOn) {
      if (reviewedBy === undefined || reviewedBy === '') {
        const position = positions.find(p => p._id === Number(values.position))
        if (position) {
          setFieldValue('resume.reviewed_by', position.hiring_manager)
        }
      }
    }
    reviewedOnRef.current = reviewedOn
  }, [values.position, values.resume, positions, setFieldValue])

  useEffect(() => {
    if (position && !id) {
      setFieldValue('position', position)
    }
  }, [position, id, setFieldValue])

  useEffect(() => {
    if (distribution && !id) {
      setFieldValue('distribution', distribution)
    }
  }, [distribution, id, setFieldValue])

  return (
    <FormikForm autoComplete="off">
      <Title
        id={id}
        plaintext={props.plaintext}
        auth='CAT_EDIT'
        titleText={{
          new: 'New Candidate',
          edit: `Candidate - ${values.name?.last}, ${values.name?.first}`,
          save: `Editing - ${values.name?.last}, ${values.name?.first}`
        }}
        actionProps={{
          save: {
            dirty: dirty,
            saving: props.saving,
            onClick: () => {
              props.submitForm()
            }
          },
          edit: {
            tag: Link,
            to: `/cat/candidates/${id}/edit`
          }
        }}
      />
      <Tabs
        activeTab={activeTab}
        changeTab={setTab}
        exhibit={exhibit}
        offerletter={offerletter}
      />
      <Details
        options={options}
        positions={positionOptions}
        distributions={createDistributionOptions()}
        filteredDistributions={createDistributionOptions(values.position)}
        active={activeTab === 'details'}
        position={currentPosition}
        salary={values.salary}
        bill_rate={values.bill_rate}
      />

      <Status
        options={options}
        status={values.status}
        active={activeTab === 'status'}
      />

      {exhibit
        ? <Exhibit active={activeTab === 'exhibit'} />
        : null
      }

      {offerletter
        ? <OfferLetter active={activeTab === 'offerletter'} />
        : null
      }

      {activeTab === 'attachments'
        ? (
          <Attachments
            title='Attachments'
            state={attachmentsState}
            methods={attachmentsMethods}
            edit={!plaintext && checkPermissions('CAT_EDIT')}
          />
        )
        : null
      }

      <Nda active={activeTab === 'nda'} />
      <br />
      <h3>Notes</h3>
      <hr />
      { props.plaintext
        ? (
          <Row>
            <Col className='font-weight-normal mb-3'>
              {values.notes}
            </Col>
          </Row>
        ) : (
          <Input name='notes' type='textarea' inputProps={{ rows: '7' }} />
        )
      }
      {id ? (
        <Row>
          <Col md={6}>
            <DatePicker plaintext datePickerProps={{ dateFormat: "MM/dd/yyyy h:mm aa" }} name='createdAt' label='Created On:' />
          </Col>
          <Col md={6}>
            <DatePicker plaintext datePickerProps={{ dateFormat: "MM/dd/yyyy h:mm aa" }} name='updatedAt' label='Last Updated On:' />
          </Col>
        </Row>
      ) : null}
      <Row>
        { !props.plaintext && checkPermissions('CAT_EDIT')
          ? (
            <>
              <Col>
                <SaveButton block dirty={dirty} saving={props.saving} />
              </Col>
              {id 
                ? (
                  <Col md={3}>
                    <RemoveButton
                      onClick={remove}
                      title={values.name ? `${values.name.last}, ${values.name.first}` : null}
                    />
                  </Col>
                ) : null
              }
            </>
          ) : null
        }
      </Row>
    </FormikForm>
  )
}

export default Form
