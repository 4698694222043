import React, { useEffect } from 'react'
import { Form as FormikForm, FormikProps } from 'formik';
import { Row, Col } from 'reactstrap';
import { useLocation, Link } from 'react-router-dom'
import { Input, DatePicker, Select } from '@imcva/formik-reactstrap-widgets'
import Distribution from '@imcva/imc-hris-common/types/distribution'
import querystring from 'query-string'

import Attachments from '../../../../components/Attachments'
import RemoveButton from '../../../../components/FormHelpers/RemoveButton';
import Title from '../../../../components/FormHelpers/Title';
import SaveButton from '../../../../components/FormHelpers/SaveButton';
import { AttachmentState } from '../../../../hooks/useAttachments/attachmentReducer'
import { AttachmentMethods } from '../../../../hooks/useAttachments'
import { getLookupText, SelectOption, lookupSelectMap } from '../../../../hooks/useLookups'
import { Lookups } from './lookups'
import { useAuth } from '../../../../hooks/useAuth'

interface FormProps {
  attachments: {
    attachmentsState: AttachmentState,
    attachmentsMethods: AttachmentMethods
  }
  createPositionOptions: Array<{
    text: string;
    value: number;
  }>,
  dirty: boolean
  formik: FormikProps<Partial<Distribution>>
  id: string | undefined
  lookups: Lookups | undefined
  options: Record<keyof Lookups, SelectOption[]> | undefined
  plaintext: boolean | undefined
  remove: () => Promise<void>
  saving: boolean
}

const Form: React.FC<FormProps> = (props) => {
  const { 
    attachments: {
      attachmentsMethods,
      attachmentsState
    },
    createPositionOptions,
    dirty,
    formik,
    id,
    lookups,
    options,
    plaintext,
    remove,
    saving,
  } = props
  const { setFieldValue } = formik
  const { checkPermissions } = useAuth()
  const location = useLocation()
  const { position } = querystring.parse(location.search)

  const filteredSubcategories = (category?: string) => {
    return lookups?.Distribution_Subcategory.filter(sub => {
      return sub.category === category
    })
  }

  useEffect(() => {
    if (position && !id) {
      setFieldValue('position', position)
    }
  }, [position, id, setFieldValue])

  return (
    <FormikForm autoComplete="off">
      <Title
        id={id}
        plaintext={plaintext}
        auth='CAT_EDIT'
        titleText={{
          new: 'New Distribution',
          edit: `Distribution`,
          save: `Editing Distribution`
        }}
        actionProps={{
          save: {
            dirty: dirty,
            saving,
            onClick: () => {
              formik.submitForm()
            }
          },
          edit: {
            tag: Link,
            to: `/cat/distributions/${id}/edit`
          }
        }}
      />
      <Select
        name='position'
        label='Position'
        options={createPositionOptions}
        InsertBlank
      />
      <Select
        name='category'
        label='Category'
        options={options?.Distribution_Category || []}
        InsertBlank
      />
      <Select
        name='subcategory'
        label='Subcategory'
        options={options?.Distribution_Category || []}
        filtered={lookupSelectMap(filteredSubcategories(formik.values.category) || [])}
        InsertBlank
      />
      <DatePicker name='date_posted' label='Date Posted' />
      <Attachments
        title='Attachments'
        state={attachmentsState}
        methods={attachmentsMethods}
        edit={!plaintext && checkPermissions('CAT_EDIT')}
      />
      <Input name='notes' label='Notes' rows='6' type='textarea' />
      {id ? (
        <Row>
          <Col md={6}>
            <DatePicker plaintext datePickerProps={{ dateFormat: "MM/dd/yyyy h:mm aa" }} name='createdAt' label='Created On:' />
          </Col>
          <Col md={6}>
            <DatePicker plaintext datePickerProps={{ dateFormat: "MM/dd/yyyy h:mm aa" }} name='updatedAt' label='Last Updated On:' />
          </Col>
        </Row>
      ) : null}
      {!plaintext && checkPermissions('CAT_EDIT')
        ? (
          <Row>
            <Col className='mb-2'>
              <SaveButton block dirty={dirty} />
            </Col>
            {id
              ? (
                <Col md={3}>
                  <RemoveButton
                    onClick={remove}
                    title={`${getLookupText(lookups?.Distribution_Category, formik.values.category)?.name || ''} - ${getLookupText(lookups?.Distribution_Subcategory, formik.values.subcategory)?.name || ''}`}
                  />
                </Col>
              ) : null
            }
          </Row>
        ) : null}
    </FormikForm>
  )
}

export default Form
