import axios from "axios"
import { Auth } from 'aws-amplify'

const config = () => {
  axios.defaults.baseURL = process.env.REACT_APP_API_URL || 'http://localhost:4000'

  // Intercept every request and add header token
  axios.interceptors.request.use(async (config) => {
    const session = await Auth.currentSession()
    const token = session.getAccessToken().getJwtToken()
    if (token) {
      config.headers.common['Authorization'] = token
    }
    return config;
  });
}

export default config
