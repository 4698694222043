import React, { useState, useEffect } from 'react'
import { Button, Col, Table, Row } from 'reactstrap'
import { ListOptionsProps } from './types'
import { ILookupConfig } from '@imcva/imc-hris-common/types'
import { useRouteMatch, Link } from 'react-router-dom'

import LoadingAuth from './LoadingAuth'

interface ListOptionRouteParams {
  lookup: string
}

interface Option {
  value: any,
  text: string,
  archived?: boolean
}

const Options: React.FC<ListOptionsProps> = (props) => {
  const { checkPermissions, getOptions } = props
  const { params, url } = useRouteMatch<ListOptionRouteParams>()
  const [ items, setItems ] = useState<Array<{ [key: string]: any }> | undefined>()
  const [ config, setConfig ] = useState<ILookupConfig | undefined>()

  useEffect(() => {
    const getItems = async () => {
      const result = await getOptions(params.lookup)
      setItems(result.items)
      setConfig(result.config)
    }
    getItems()
  }, [url, params.lookup, getOptions])

  return (
    <LoadingAuth loading={!items || !config } checkPermissions={checkPermissions} config={config}>
      <Row className='mb-3'>
        <Col>
          <h2>
            {config && config.name ? config.name : 'Lookup'}
            <Button tag={Link} to={`${url}/add`} size='sm' className='ml-3 mr-1'>+ Add Option</Button>
            <Button tag={Link} to={`${url}/config`} size='sm'>Lookup Config</Button>
          </h2>
        </Col>
      </Row>
      <Table>
        <thead>
          <tr>
            { config && config.fields
              ? (
                <>
                  {config.fields.map((field, index) => {
                    return (<th key={index}>{field.title}</th>)
                  })}
                  <th>Links</th>
                </>
              ) : null
            }
          </tr>
        </thead>
        <tbody>
          { items && config && config.fields
            ? items.map((item, index) => {
                const archived = item.archived
                return (
                  <tr key={index} className={archived ? 'text-secondary' : ''}>
                    {config.fields.map((field, index) => {
                      const { component, key } = field
                      const options = component.props && component.props.options ? component.props.options : undefined
                      const value = item[key]
                      const option = options ? options.find((opt: Option) => opt.value === value) : undefined
                      const text = option ? option.text : value
                      return (
                        <td key={index}>
                          {index === 0
                            ? (
                              <a href={`${url}/${item._id}`} className={archived ? 'text-secondary' : ''}>{text}</a>
                            ) : text
                          }
                        </td>
                      )
                    })}
                    <td>
                      {typeof item.relationshipCount === 'number'
                        ? (
                          <Link to={`${url}/${item._id}/links`}>Links ({item.relationshipCount})</Link>
                        ) : null
                      }
                    </td>
                  </tr>
                )
              })
            : (
              <h2>Failed to Load!</h2>
            )
          }
        </tbody>
      </Table>
    </LoadingAuth>
  )
}

export default Options
