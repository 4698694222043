import React from 'react'

const Unauthorized: React.FC = () => {
  return (
    <div className='mt-n5 vh-100 w-100 d-flex'>
      <div className='m-auto'>
        <h2>You are unauthorized to view this page!</h2>
      </div>
    </div>
  )
}

export default Unauthorized
