import axios from 'axios'

interface Data {
  file: File
  type: string
  subtype?: string
  link: string
  model: string
  additional?: Record<string, string>
}

type UploadProgress = (percentage: number) => void

type UploadAttachment = (
  data: Data,
  progress: UploadProgress
) => Promise<{ status: string } | undefined>

/**
 * Upload a single attachment.
 * 
 * @param {Object} data - An object containing the attachment query info
 * @param {File} data.file - The file object to upload.
 * @param {String} data.type - The ObjectId of the Attachment Type
 * @param {String} data.subtype - The ObjectId of the Attachment Subtype
 * @param {String} data.link - The ObjectId of linking object
 * @param {String} data.model - The model name of the linking object
 * @param {Object} [data.additional] - An object that can be used for additonal filtering
 * 
 * @returns {Promise<{success: boolean, result: Attachment}>}
 */
const uploadAttachment: UploadAttachment = async (data, progress) => {
  const request = await axios.post('/attachments/upload/request', {
     link: data.link,
     type: data.type,
     subtype: data.subtype,
     model: data.model,
     additional: data.additional,
     filename: data.file.name,
     filetype: data.file.type
  })
  const url = request.data?.url
  const uploadId = request.data?.result?._id
  if(typeof url === 'string' && typeof uploadId === 'string') {
    var instance = axios.create()
    instance.defaults.headers.common = {}
    const result = await instance.put(
      url,
      data.file,
      {
        headers: {
          'Content-Type': data.file.type
        },
        onUploadProgress: (progressEvent) => {
          if(typeof progress === 'function') {
            const percent = (progressEvent.loaded / progressEvent.total) * 100
            progress(percent)
          }
        }
      }
    )
    if(result.status === 200) {
      return await axios.post(`/attachments/upload/complete/${uploadId}`)
    }
  } else {
    throw new Error('Unable to generate upload url.')
  }
}

export default uploadAttachment
