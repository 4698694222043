const sortWithList = (list) => {
  return (a, b, columnId, desc) => {
    const values = {
      a: a.values[columnId],
      b: b.values[columnId]
    }
    a = getPositionNumber(values.a, list)
    b = getPositionNumber(values.b, list)
    if (a > b) {
      return 1
    }
    if (a < b) {
      return -1
    }
    // returning 0 or undefined will use any subsequent column sorting methods or the row index as a tiebreaker
    return 0
  }
}

const getPositionNumber = (item, list) => {
  const lower_list = list.map(value => {
    return typeof value === 'string' ? value.toLowerCase() : value
  })
  const lower_item = typeof item === 'string' ? item.toLowerCase() : item
  const index = lower_list.indexOf(lower_item)
  if (item === null || item === undefined || index === -1) {
    return -Infinity    
  } else {
    return index
  }
}

export default sortWithList
