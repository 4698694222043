import React from 'react'

const TextBackground = (props) => {
  const { hide, children, label, highlightColor, highlight } = props
  if (!hide) {
    return (
      <div 
        style={{
          backgroundColor: highlight ? highlightColor : null,
          width: '100%',
          minHeight: '1.5rem'
        }}
      >
        {label ? <b>{label}: </b> : null}
        {children}
      </div>
    )
  } else {
    return null
  }
}

export default TextBackground
