import React, { useState } from 'react'
import { Button } from 'reactstrap'

import Modal from './Modal'

const RemoveButton = (props) => {
  const [ modal, setModal ] = useState(false)

  const toggleModal = () => {
    setModal(m => !m)
  }

  return (
    <>
      <Button block color='danger' onClick={toggleModal}>
        Remove
      </Button>
      <Modal 
        open={modal}
        toggle={toggleModal}
        onConfirm={props.onClick}
        title={props.title}
      />
    </>
  )
}

export default RemoveButton
