import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { Row, Col } from 'reactstrap'
import { TableContext, Table, GlobalSearch } from '@imcva/reactstrap-table'
import { useGlobalFilter, useSortBy, useColumnOrder } from 'react-table'

import useItems from '../../hooks/useItems'
import columns from './columns'
import Loading from '../../components/Loading'
import { JobDescriptionQuery } from '@imcva/imc-hris-common/types'

const List: React.FC<RouteComponentProps> = (props) => {
  const [ items, , loading ] = useItems<JobDescriptionQuery>('/cat/jobdesc')

  return (
    <TableContext<JobDescriptionQuery> 
      options={{ 
        columns,
        data: items,
      }}
      storageKey='job-description-list'
      plugins={[useGlobalFilter, useSortBy, useColumnOrder]}
    >
      <Row className='mb-3 justify-content-end align-items-end'>
        <Col>
          <h3 className='mb-0'>Job Description Database</h3>
        </Col>
        <Col xl="4" md="6" className=''>
          <GlobalSearch placeholder='Search' />
        </Col>
      </Row>
      <Loading loading={loading}>
        <Table responsive stickyHeader responsiveHeight='calc(-125px + 100vh)' />
      </Loading>
    </TableContext>
  )
}

export default List
