import React, { useMemo, useState, useEffect } from 'react'
import { Row, Col, Modal, ModalBody, ModalFooter, ModalHeader, Button } from 'reactstrap'
import { FormikHelpers, FormikProps } from 'formik'
import { API } from 'aws-amplify'
import { Link, useHistory, useParams } from 'react-router-dom'
import { Formik } from '@imcva/formik-reactstrap-widgets'
import Position from '@imcva/imc-hris-common/types/position'
import Distribution from '@imcva/imc-hris-common/types/distribution'
import Candidate from '@imcva/imc-hris-common/types/candidate'

import FormikForm from './Form'

import useAttachments from '../../../../hooks/useAttachments';
import useItem from '../../../../hooks/useItem'
import useItems from '../../../../hooks/useItems'
import useTitle from '../../../../hooks/useTitle';
import useLookups from '../../../../hooks/useLookups';
import { useAlerts } from '../../../../hooks/useAlerts';
import useContractPermissions from '../../useContractPermissions';

import Loading from '../../../../components/Loading';

import { Lookups } from './lookups.d'
import Authorized from '../../../../components/Authorized'
import { useAuth } from '../../../../hooks/useAuth'

interface FormProps {
  plaintext?: boolean
}

interface DialogState {
  open: boolean,
  matches: Candidate<'populate'>[],
  values?: Partial<Candidate>
  formik?: FormikHelpers<Partial<Candidate>>
}

const Form: React.FC<FormProps> = (props) => {
  const { id } = useParams<{ id?: string }>()
  const history = useHistory()
  const { checkPermissions } = useAuth()
  const [ item, setItem, itemLoading, itemRemove ] = useItem<Candidate>(id ? `/cat/candidates/${id}` : undefined)
  const [ positions, , positionsLoading ] = useItems<Position<'populate'>>('/cat/positions')
  const [ distributions, , distributionsLoading ] = useItems<Distribution<'populate'>>('/cat/distributions')
  const contract = useMemo(() => {
    return positions.find(p => Number(p._id) === Number(item?.position))?.contract
  }, [positions, item])
  const [ contractAuth, loadingContractAuth ] = useContractPermissions(contract)
  const [ , , loadingLookups, select ] = useLookups<Lookups>([
    "Candidate_Rank",
    "Candidate_Status",
    "Candidate_Substatus",
    "Candidate_Type",
    "Hiring_Manager",
    "Clearance"
  ])
  const uploadData = useMemo(() => ({
    link: id,
    type: '6058d4799c5e6a11b0083ac2',
    model: 'Candidate',
  }), [id])
  const [ attachmentsState, attachmentsMethods ] = useAttachments(uploadData)

  useEffect(() => {
    if(
      uploadData.type !== undefined &&
      uploadData.link !== undefined
    ) {
      const get = attachmentsMethods.get
      get()
    }
  }, [uploadData, attachmentsMethods.get])
  const defaultDialog = {
    open: false,
    matches: [],
  }
  const [ dialog, setDialog ] = useState<DialogState>(defaultDialog)
  const [ saving, setSaving ] = useState(false)
  const { addAlert } = useAlerts()
  const getTitle = () => {
    if (id) {
      if(item?.name) {
        return `${item.name.last || ''}, ${item.name.first || ''} | Edit Candidate`
      } else {
        return 'Edit Candidate'
      }
    } else {
      return 'Add Candidate'
    }
  }
  useTitle(getTitle())

  const closeDialog = () => {
    setDialog(defaultDialog)
    setSaving(false)
  }

  const verifyCandidateName = async (values: Partial<Candidate>, formik: FormikHelpers<Partial<Candidate>>) => {
    const { name } = values
    setSaving(true)
    if (!id && name) {
      const { first, last } = name
      const url = `/cat/candidates/findByName?first=${first}&last=${last}`
      const matches = await API.get('hris', url, {})
      if (matches.items && matches.items.length) {
        setDialog({
          open: true,
          matches: matches.items,
          values,
          formik
        })
      } else {
        saveItem(values, formik)
      }
    } else {
      saveItem(values, formik)
    }
  }

  const authorized = props.plaintext ? !!(contractAuth || checkPermissions('CAT_VIEW')) : !!checkPermissions('CAT_EDIT')

  const saveItem = async (values: Partial<Candidate>, formik: FormikHelpers<Partial<Candidate>>) => {
    try {
      setSaving(true)
      if(!attachmentsState.canSave) {
        throw new Error('Invalid attachment. Please try again!')
      }
      let url = '/cat/candidates'
      url = id ? url + `/${id}` : url
      const results = await API.post('hris', url, {
        body: values
      })
      if (results) {
        const item = results.item
        if(item._id && attachmentsState.canSave) {
          await attachmentsMethods.save({ link: item._id })
        }
        setItem(item)
        formik.resetForm()
        let url = '/cat/candidates'
        if (item.position) {
          url = `/cat/positions/${item.position}/candidates`
        }
        history.push(url)
      }
    } catch (error) {
      addAlert(`Could not save item!`)
    } finally {
      setSaving(false)
    }
  }

  const remove = async () => {
    const removed = await itemRemove()
    if (removed.success) {
      let url = '/cat/candidates'
      if (item?.position) {
        url = `/cat/positions/${item.position}/candidates`
      }
      history.push(url)
    }
  }

  return (
    <Loading loading={itemLoading || positionsLoading || distributionsLoading || loadingLookups || loadingContractAuth }>
      <Authorized authorized={authorized}>
        <Row className='mb-5'>
          <Col className='mx-auto font-weight-bold' xl={6} lg={8} md={10} sm={12}>
            <Formik
              onSubmit={(values, formik) => verifyCandidateName(values, formik)}
              initialValues={item || {}}
              enableReinitialize
              plaintext={props.plaintext}
              render={(formikProps: FormikProps<Partial<Candidate>>) => (
                <FormikForm
                  plaintext={props.plaintext}
                  item={item}
                  id={id}
                  remove={remove}
                  saving={saving}
                  options={select}
                  positions={positions}
                  distributions={distributions}
                  attachments={{
                    attachmentsMethods,
                    attachmentsState
                  }}
                  {...formikProps}
                />
              )}
            />
          </Col>
          <Modal isOpen={dialog.open} toggle={closeDialog}>
            <ModalHeader toggle={closeDialog}>Duplicate Candidate?</ModalHeader>
            <ModalBody>
              <p>
                Candidate(s) with the same first and last name have already been submitted.
                Would you like to create this candidate anyways?
              </p>
              <ul>
                {dialog.matches.map((match, index) => {
                  const { id, name: { first, last }, position} = match
                  return (
                    <li key={index}>
                      <Link to={`/cat/candidates/${id}`} target='_blank' rel='noopener noreferrer'>{last}, {first} ({position.id} - {position.title})</Link>
                    </li>
                  )
                })}
              </ul>
            </ModalBody>
            <ModalFooter>
              <Button color='secondary' onClick={closeDialog}>Cancel</Button>
              <Button color='primary' onClick={() => dialog.values && dialog.formik ? saveItem(dialog.values, dialog.formik) : null}>Save</Button>
            </ModalFooter>
          </Modal>
        </Row>
      </Authorized>
    </Loading>
  )
}

export default Form
