import React from 'react'
import { useRouteMatch } from 'react-router-dom'
import AuthRoute from '../../../components/AuthRoute'
import CustomSwitch from '../../../components/CustomSwitch'
import Metrics from './Metrics'

import Overview from './Overview'

const Routes: React.FC = (routerProps) => {
  const { path } = useRouteMatch()

  return (
    <CustomSwitch>
      <AuthRoute permissionsKey='CAT_REPORT' exact path={`${path}/overview`} render={(props) => (
        <Overview {...routerProps} {...props} />
      )} />
      <AuthRoute permissionsKey='CAT_REPORT' exact path={`${path}/metrics`} render={(props) => (
        <Metrics {...routerProps} {...props} />
      )} />
    </CustomSwitch>
  )
}

export default Routes



