import React from 'react'
import UploadDate from './UploadDate'
import { AttachmentItem } from '../../../hooks/useAttachments/attachmentReducer'

interface S3AttachmentProps {
  attachment: AttachmentItem
}

const S3Attachment: React.FC<S3AttachmentProps> = (props) => {
  const { attachment }  = props
  const { filename, createdAt, signed, } = attachment

  return (
    <>
      {attachment.delete
        ? (
          <span className='text-muted mr-1'>
            <del>
              {filename}
            </del>
            <UploadDate date={createdAt} />
            <small>(pending delete)</small>
          </span>
        ) : (
          <>
            <a target="_blank" rel="noopener noreferrer" href={signed}>{filename}</a>
            <UploadDate date={createdAt} />
          </>
        )}
    </>
  )
}

export default S3Attachment
