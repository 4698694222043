import React from 'react'
import { Nav, NavItem, NavLink } from 'reactstrap'

interface TabsProps {
  activeTab: string,
  changeTab: (tabName: string) => void
  exhibit: boolean
  offerletter: boolean
}

const Tabs: React.FC<TabsProps> = (props) => {
  const { activeTab, changeTab } = props
  const tabProps = (tabName: string) => {
    return {
      active: activeTab === tabName,
      onClick: () => changeTab(tabName)
    }
  }
  return (
    <div className='mb-3'>
      <Nav tabs>
        <NavItem>
          <NavLink href="#" {...tabProps('details')}>Details</NavLink>
        </NavItem>
        <NavItem>
          <NavLink href="#" {...tabProps('status')}>Status</NavLink>
        </NavItem>
        { props.exhibit
          ? (
            <NavItem>
              <NavLink href="#" {...tabProps('exhibit')}>Exhibit</NavLink>
            </NavItem>
          ) : null
        }
        { props.offerletter
          ? (
            <NavItem>
              <NavLink href="#" {...tabProps('offerletter')}>Offer Letter</NavLink>
            </NavItem>
          ) : null
        }
        <NavItem>
          <NavLink href="#" {...tabProps('nda')}>NDA</NavLink>
        </NavItem>
        <NavItem>
          <NavLink href="#" {...tabProps('attachments')}>Attachments</NavLink>
        </NavItem>
      </Nav>
    </div>
  )
}

export default Tabs
