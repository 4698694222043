import React from 'react'
import { Route, useRouteMatch } from 'react-router-dom'
import { LookupsProps } from './types'

import List from './List'
import Options from './Options'
import OptionForm from './OptionForm'
import ConfigForm from './ConfigForm'
import Links from './Links'
import CustomSwitch from '../CustomSwitch'

const Routes: React.FC<LookupsProps> = (routerProps) => {
  const { path } = useRouteMatch()
  return (
    <CustomSwitch>
      <Route exact path={path} render={(props) => (
        <List {...routerProps} {...props} />
      )} />
      <Route exact path={`${path}/:lookup`} render={(props) => (
        <Options {...routerProps} {...props} />
      )} />
      <Route exact path={`${path}/:lookup/config`} render={(props) => (
        <ConfigForm {...routerProps} {...props} />
      )} />
      <Route exact path={`${path}/:lookup/add`} render={(props) => (
        <OptionForm {...routerProps} {...props} />
      )} />
      <Route exact path={`${path}/:lookup/:option`} render={(props) => (
        <OptionForm {...routerProps} {...props} />
      )} />
      <Route exact path={`${path}/:lookup/:option/links`} render={(props) => (
        <Links {...routerProps} {...props} />
      )} />
    </CustomSwitch>
  )
}

export default Routes



