import React, { useState, useRef } from 'react'
import { Tooltip } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import classname from 'classnames'

interface IndicatorProps {
  currentValue?: number
  maxValue?: number
  prefix: string
}

const Indicator: React.FC<IndicatorProps> = (props) => {
  const { currentValue, maxValue, prefix } = props
  const iconEl = useRef(null);
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);

  const formatter = Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2
  })

  const fraction = Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0
  })

  const danger = currentValue !== undefined
    && maxValue !== undefined
    && currentValue > maxValue

  const warning = maxValue === undefined

  const formated = maxValue === undefined
    ? '?'
    : maxValue % 1 === 0
    ? fraction.format(maxValue)
    : formatter.format(maxValue)

  return (
    <>
      <span ref={iconEl}>
        <FontAwesomeIcon
          title='Attachment'
          icon={danger ? faExclamationTriangle : faInfoCircle}
          className={classname(
            'mx-2',
            { 
              'text-danger': danger,
              'text-warning': warning
            }
          )}
        />
      </span>
      <Tooltip placement="right" isOpen={tooltipOpen} target={iconEl} toggle={toggle}>
        {prefix}: {formated}
      </Tooltip>
    </>    
  )
}

export default Indicator
