import React from 'react'
import { Row, Col, FormGroup } from 'reactstrap'
import { Select, Input, DatePicker } from '@imcva/formik-reactstrap-widgets'

import { SelectOption } from '../../../../hooks/useLookups'
import { FormSelectOption } from '@imcva/imc-hris-common/types/helpers'
import Candidate from '@imcva/imc-hris-common/types/candidate'
import Position from '@imcva/imc-hris-common/types/position'

import { Lookups } from './lookups.d'
import Indicator from './Indicator'

interface DetailsProps {
  active: boolean,
  options: Record<keyof Lookups, SelectOption[]> | undefined
  positions: FormSelectOption[]
  distributions: FormSelectOption[]
  filteredDistributions: FormSelectOption[]
  salary?: Candidate['salary']
  bill_rate?: Candidate['salary']
  position?: Position<'populate'>
}

const Details: React.FC<DetailsProps> = (props) => {
  const { salary, bill_rate, position } = props
  
  if (props.active) {
    return (
      <>
        <Select
          name='position'
          label='Position'
          options={props.positions}
          InsertBlank
        />
        <Select
          name='distribution'
          label='Distribution'
          options={props.distributions}
          filtered={props.filteredDistributions}
          InsertBlank
        />
        <Row>
          <Col md={6}>
            <Input name='name.first' label='First Name' />
          </Col>
          <Col md={6}>
            <Input name='name.last' label='Last Name' />
          </Col>
        </Row>
        <Select
          name='type'
          label='Type'
          options={props.options?.Candidate_Type}
          InsertBlank
        />
        <DatePicker name='received_on' label='Received On' />
        <Input name='location' label='Location' />
        <Select
          name='clearance'
          options={props.options?.Clearance}
          label='Clearance'
          InsertBlank
        />
        <Row>
          <Col md={6}>
            <FormGroup>
              <label htmlFor="bill_rate.min" data-testid="input-label">Bill Rate (Min)</label>
              <Indicator currentValue={bill_rate?.min} maxValue={position?.bill_rate?.min} prefix='Position Min' />
              <Input name='bill_rate.min' type='number' />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <label htmlFor="bill_rate.max" data-testid="input-label">Bill Rate (Max)</label>
              <Indicator currentValue={bill_rate?.max} maxValue={position?.bill_rate?.max} prefix='Position Max' />
              <Input name='bill_rate.max' type='number' />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup>
              <label htmlFor="bill_rate.max" data-testid="input-label">Salary (Min)</label>
              <Indicator currentValue={salary?.min} maxValue={position?.salary?.min} prefix='Position Min' />
              <Input name='salary.min' type='number' />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <label htmlFor="bill_rate.max" data-testid="input-label">Salary (Max)</label>
              <Indicator currentValue={salary?.max} maxValue={position?.salary?.max} prefix='Position Max' />
              <Input name='salary.max' type='number' />
            </FormGroup>
          </Col>
        </Row>
      </>
    )
  } else {
    return null
  }
}

export default Details
