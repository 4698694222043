import React, { useEffect } from 'react'
import { DatePicker, Select, Checkbox } from '@imcva/formik-reactstrap-widgets'
import { Form as FormikForm, FormikProps } from 'formik';
import Distribution from '@imcva/imc-hris-common/types/distribution';
import SaveButton from '../../../../components/FormHelpers/SaveButton';
import querystring from 'query-string'
import { useLocation } from 'react-router-dom'

interface BulkFormProps {
  saving: boolean
  options: {
    positions: Array<{
      text: string;
      value: number;
    }>
    distributions: Array<{
      text: string;
      name: string;
    }>
  }
  formik: FormikProps<Partial<BulkValue>>
}

interface BulkValue {
  position: Distribution['position'],
  date_posted: Distribution['date_posted'],
  subcategories: Record<string, boolean>
}

const BulkForm: React.FC<BulkFormProps> = (props) => {
  const { formik, saving, options } = props
  const location = useLocation()
  const { position } = querystring.parse(location.search)
  const { setFieldValue } = formik

  useEffect(() => {
    if (position) {
      setFieldValue('position', position)
    }
  }, [position, setFieldValue])

  return (
    <FormikForm>
      <h2>
        Bulk Distribution
        <SaveButton className='float-right' saving={saving} dirty={formik.dirty} />
      </h2>
      <hr />
      <Select
        name='position'
        label='Position'
        options={options.positions}
        InsertBlank
      />
      <DatePicker name='date_posted' label='Date Posted' />
      <Checkbox
        multiple
        button
        block
        label='Distribution'
        color='primary'
        className='text-left'
        outline
        options={options.distributions}
      />
      <SaveButton saving={saving} dirty={formik.dirty} block className='mb-3' />
    </FormikForm>
  )
}

export default BulkForm
