import React, { useEffect, useState, createContext, useContext, useCallback } from 'react'
import{ useAlerts } from './useAlerts'

import { Auth, Hub, API } from 'aws-amplify'

interface IProfileResponse {
  item?: {
    email: string
    role: {
      defaultPage: string
      [key: string]: any
    }
    defaultPage: string
    createdBy?: object
    updatedBy?: object
  }
  success: boolean
}

export interface IUser {
  profile?: IProfileResponse['item']
  [key: string]: any
}

interface IAuthContext {
  user: IUser | undefined
  loading: boolean
  login: () => void
  logout: () => void
  checkPermissions: (permissionKey: string) => boolean
}

const AuthContext = createContext<IAuthContext>({
  user: undefined,
  loading: true,
  login: () => null,
  logout: () => null,
  checkPermissions: () => false
})

const AuthProvider: React.FC = (props) => {
  const [ user, setUser ] = useState<IUser | undefined>(undefined)
  const { addAlert } = useAlerts()
  const [ loading, setLoading ] = useState(true)

  const updateUser = React.useCallback(async (user: any) => {
    if (user) {
      try {
        const profile: IProfileResponse = await API.get('hris', '/users/self', {})
          if (profile.item) {
            setUser({ ...user, profile: profile.item })
          }
      } catch (e) {
        addAlert('There was an issue loading your profile. Please contact the system adminstrator.')
      }
    } else {
      setUser(user)
    }
  }, [addAlert])

  const checkPermissions = (permissionKey: string): boolean => {
    if (permissionKey && user?.profile?.role && user?.profile?.role.permissions) {
      const permission = user.profile.role.permissions.find((p: any) => p.key === permissionKey)
      if (permission) {
        return true
      }
    }
    return false
  }

  useEffect(() => {
    Hub.listen("auth", ({ payload }) => {
      const event = payload.event
      switch (event) {
        case "signIn":
          Auth.currentAuthenticatedUser()
            .then(user => updateUser(user))
            .catch(user => console.log('Not Signed In'))
            .finally(() => setLoading(false))
          break;
        case "signOut":
          updateUser(undefined)
          break;
        default:
          break;
      }
      setLoading(false)
    }, 'AuthSignIn');

    Auth.currentAuthenticatedUser()
      .then(user => updateUser(user))
      .catch(user => console.log('Not Signed In'))
      .finally(() => setLoading(false))
  }, [updateUser])

  const login = useCallback(() => {
    Auth.federatedSignIn({customProvider: 'IMCOffice365'})
  }, [])
  
  const logout = useCallback(() => {
    setUser(undefined)
    Auth.signOut()
  }, [])

  return (
    <AuthContext.Provider value={{ loading, user, login, logout, checkPermissions }}>
      {props.children}
    </AuthContext.Provider>
  )
}

const useAuth = () => {
  return useContext(AuthContext)
}

export { useAuth, AuthProvider, AuthContext }
