import React, { ReactElement } from 'react'
import { Link } from 'react-router-dom'
import { Column as ReactTableColumn, UseSortByColumnOptions, CellProps } from 'react-table'
import Position from '@imcva/imc-hris-common/types/position'
import AttachmentCount from '../../../../components/AttachmentCount'

import { 
  Dollar,
  NumberRange,
  CellBackground,
  sortWithList,
  TextBackground,
  DateFormat
} from '../../../../components/TableHelpers'

const status_color_map: { [key: string]: string } = {
  'Open': 'success',
  'Paused': 'secondary',
  'Tabled': '',
  'Filled': ''
}

type Cell<V = any> = CellProps<Position<'populate'>, V>

const danger = '#f8d7da'

interface ExtendedOptions {
  className?: string
  Cell?: (props: Cell<string>) => ReactElement | ReactElement[] | string | number | null 
}

type Column = ReactTableColumn<Position<'populate'>> & UseSortByColumnOptions<Position<'populate'>> & ExtendedOptions

const columns = (statuses: string[]): Column[] => [
  {
    id: 'attachments',
    Header: 'Attachments',
    accessor: 'attachment_count',
    className: 'text-center align-middle',
    Cell: (cell: any) => {
      return <AttachmentCount value={cell.value} />
    }
  },
  {
    id: 'title',
    Header: 'Title',
    accessor: d => `(${d.id || ''}) ${d.title || ''}`,
    Cell: ({ row: { original }, value }) => {
      if (original.id) {
        return <Link to={`/cat/positions/${original._id}`}>{value}</Link>
      }
      return (
        <>{value}</>
      )
    }
  },
  {
    Header: 'Priority',
    accessor: d => d.priority?.name,
  },
  {
    Header: 'Status',
    accessor: d => d.status?.name,
    sortType: sortWithList(statuses),
    className: 'h-100 p-0',
    Cell: ({ cell: { value } }) => ( 
      <CellBackground color={status_color_map[value]} >
        {value}
      </CellBackground>
    )
  },
  {
    Header: 'Contract',
    accessor: d => d.contract?.name
  },
  {
    id: 'program_manager',
    Header: 'Program Manager',
    accessor: d => d.program_manager?.name
  },
  {
    id: 'hiring_manager',
    Header: 'Hiring Manager',
    accessor: d => d.hiring_manager?.name,
  },
  {
    id: 'job_description',
    Header: 'Job Description',
    className: 'text-nowrap',
    accessor: (d) => {
      const received = d.job_desc_received
      const finalized = d.job_desc_finalized
      const posted = d.job_desc_posted
      return `${received}, ${finalized}, ${posted}`
    },
    Cell: ({ row: { original }}) => {
      const received = original.job_desc_received
      const finalized = original.job_desc_finalized
      const posted = original.job_desc_posted
      return (
        <>
          <TextBackground label='Received' highlight={!received} highlightColor={danger}>
            <DateFormat value={received} format='P' />
          </TextBackground>
          <TextBackground hide={!received} label='Finalized' highlight={!finalized} highlightColor={danger}>
            <DateFormat value={finalized} format='P' />
          </TextBackground>
          <TextBackground hide={!finalized} label='Posted' highlight={!posted} highlightColor={danger}>
            <DateFormat value={posted} format='P' />
          </TextBackground>
        </>
      )
    }
  },
  {
    Header: 'Job Description - Received',
    accessor: 'job_desc_received',
    Cell: ({ cell: { value }}) => (
      <TextBackground label='' highlight={!value} highlightColor={danger}>
        <DateFormat value={value} format='P' />
      </TextBackground>
    ),
  },
  {
    Header: 'Job Description - Finalized',
    accessor: 'job_desc_finalized',
    Cell: ({ row: { original }, cell: { value }}) => (
      <TextBackground label='' highlight={!original.job_desc_received && !value} highlightColor={danger}>
        <DateFormat value={value} format='P' />
      </TextBackground>
    )
  },
  {
    Header: 'Job Description - Posted',
    accessor: 'job_desc_posted',
    Cell: ({ row: { original }, cell: { value }}) => (
      <TextBackground label='' highlight={!original.job_desc_finalized && !value} highlightColor={danger}>
        <DateFormat value={value} format='P' />
      </TextBackground>
    )
  },
  {
    id: 'bill_rate',
    Header: 'Bill Rate',
    className: 'text-nowrap',
    accessor: (d) => (
      <NumberRange 
        min={d.bill_rate?.min}
        max={d.bill_rate?.max}
        render={(props) => (
          <Dollar value={props.value} fixed />
        )} 
      />
    )
  },
  {
    id: 'salary',
    Header: 'Salary',
    className: 'text-nowrap',
    accessor: (d) => (
      <NumberRange 
        min={d.salary?.min}
        max={d.salary?.max}
        render={(props) => (
          <Dollar value={props.value} comma />
        )} 
      />
    )
  },
  {
    Header: 'Location',
    accessor: 'location',
  },
  {
    Header: 'Clearance',
    accessor: d => d.required_clearance?.name,
  },
  {
    Header: 'Keywords',
    accessor: 'keywords',
  },
  {
    Header: 'Note',
    accessor: 'notes',
    Cell: ({ cell: { value }}) => { 
      if (typeof value === 'string') {
        return value.split("\n").map((comment) => ( 
          <>
            {comment}<br />
          </>
        ))
      } else if (!value) {
        return null
      } else {
        return (
          <>{value}</>
        )
      }
    },
  },
  {
    id: 'distributions',
    Header: 'Distributions',
    className: 'text-nowrap',
    accessor: (d) => {
      const count = d.distribution_count || null
      return `Distributions ${count ? `(${count})` : ''}`
    },
    Cell: ({ cell: { value }, row: { original }}: Cell<string>) => {
      return (
        <>
          <Link to={`/cat/positions/${original._id}/distributions`}>
            {value}
          </Link>
        </>
      )
    }
  },
  {
    id: 'candidates',
    Header: 'Candidates',
    className: 'text-nowrap',
    accessor: (d) => {
      const count = d.candidate_count || null
      return `Candidates ${count ? `(${count})` : ''}`
    },
    Cell: ({ cell: { value }, row: { original }}: Cell<string>) => {
      return (
        <>
          <Link to={`/cat/positions/${original._id}/candidates`}>
            {value} 
          </Link>
        </>
      )
    }
  },
  {
    Header: 'Created On',
    accessor: 'createdAt',
    Cell: ({ cell: { value }}: Cell<string>) => {
      if(value) {
        return (<DateFormat value={value} />)
      }
      return null 
    },
  },
  {
    Header: 'Last Updated On',
    accessor: 'updatedAt',
    Cell: ({ cell: { value }}: Cell<string>) => {
      if(value) {
        return (<DateFormat value={value} />)
      }
      return null 
    },
  }
]

export default columns
