import React from 'react'
import { Row, Col } from 'reactstrap'
import { DatePicker } from '@imcva/formik-reactstrap-widgets'

interface NdaProps {
  active: boolean
}

const Nda: React.FC<NdaProps> = (props) => {
  if (props.active) {
    return (
      <Row>
        <Col md={6}>
          <DatePicker name='nda_signed.staffing_agency' label='Staffing Agency' />
        </Col>
        <Col md={6}>
          <DatePicker name='nda_signed.candidate' label='Candidate' />
        </Col>
      </Row>
    )
  } else {
    return null
  }
}

export default Nda
