import axios from 'axios'
import { AttachmentSubtype } from '@imcva/imc-hris-common/types'

type GetAttachmentSubtypes = (type: string) => Promise<Array<AttachmentSubtype>>

const getAttachments: GetAttachmentSubtypes = async (type) => {
  if (type) {
    let url = `/attachments/subtypes/${type}`
    const results = await axios.get(url)
    if (results.data.success && results.data.items) {
      return results.data.items
    } else if (!results.data.success && results.data.message) {
      throw new Error(results.data.message)
    } else {
      throw new Error('An unexpected error occurred!')
    }
  }
}

export default getAttachments
