import React, { ReactElement } from 'react'
import { Column as ReactTableColumn, UseSortByColumnOptions, CellProps } from 'react-table'
import { JobDescriptionQuery } from "@imcva/imc-hris-common/types"
import { DateFormat } from '../../components/TableHelpers'

type Cell<V = any> = CellProps<JobDescriptionQuery, V>

interface ExtendedOptions {
  className?: string
  Cell?: (props: Cell<string>) => ReactElement | ReactElement[] | string | number | null 
}

type Column = ReactTableColumn<JobDescriptionQuery> & UseSortByColumnOptions<JobDescriptionQuery> & ExtendedOptions

const columns: Column[] = [
  {
    id: 'job_desc',
    Header: 'Job Description',
    accessor: (d) => d.attachment?.filename,
    Cell: ({ row: { original }, value }: Cell<string>) => {
      return <a target="_blank" rel="noopener noreferrer" href={original.attachment?.signed}>{value}</a>
    }
  },
  {
    Header: 'Position Number',
    accessor: 'id',
  },
  {
    Header: 'Job Title',
    accessor: 'title',
  },
  {
    Header: 'Contract',
    accessor: d => d.contract?.name
  },
  {
    Header: 'Keywords',
    accessor: 'keywords',
  },
  {
    Header: 'Uploaded On',
    accessor: (d) => d.attachment.createdAt,
    Cell: ({ cell: { value }}: Cell<string>) => {
      if(value) {
        return (<DateFormat value={value} />)
      }
      return null 
    },
  }
]

export default columns
