import React, { useState, useEffect } from 'react'
import { Col, Table, Row } from 'reactstrap'
import { LinkProps } from './types'
import { ILookupConfig } from '@imcva/imc-hris-common/types'
import { useRouteMatch } from 'react-router-dom'

import LoadingAuth from './LoadingAuth'

interface LinkRouteParams {
  lookup: string,
  option: string
}

interface Option {
  value: any,
  text: string,
  archived?: boolean
}

const Links: React.FC<LinkProps> = (props) => {
  const { checkPermissions, getLinks } = props
  const { params, url } = useRouteMatch<LinkRouteParams>()
  const [ items, setItems ] = useState<Array<{ [key: string]: any }> | undefined>()
  const [ config, setConfig ] = useState<ILookupConfig | undefined>()

  useEffect(() => {
    const getItems = async () => {
      const result = await getLinks(params.lookup, params.option)
      setItems(result.items)
      setConfig(result.config)
    }
    getItems()
  }, [url, params.lookup, params.option, getLinks])

  return (
    <LoadingAuth loading={!items || !config } checkPermissions={checkPermissions} config={config}>
      <Row className='mb-3'>
        <Col>
          <h2>
            {config && config.name ? config.name : 'Lookup'}
          </h2>
        </Col>
      </Row>
      <Table>
        <thead>
          <tr>
            <th>Link</th>
            <th>Model</th>
          </tr>
        </thead>
        <tbody>
          { items 
            ? items.map((item, index) => {
                const urlBase = process.env.REACT_APP_PUBLIC_URL || ''
                const link = item.link
                const url = `${urlBase}${link[0] === '/' ? '' : '/'}${link}`
                return (
                  <tr key={index}>
                    <td><a href={url} target='_blank' rel='noopener noreferrer'>{item.title}</a></td>
                    <td>{item.model}</td>
                  </tr>
                )
              })
            : (
              <h2>Failed to Load!</h2>
            )
          }
        </tbody>
      </Table>
    </LoadingAuth>
  )
}

export default Links
