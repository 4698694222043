import React from 'react'
import { Button, Spinner, ButtonProps } from 'reactstrap'

export interface ISaveButton extends ButtonProps {
  dirty: boolean
  saving: boolean
  text?: string
}
  
const SaveButton: React.FC<ISaveButton> = (props) => (
  <Button
    className={props.className}
    block={props.block}
    color='primary'
    disabled={props.saving || !props.dirty}
    type='submit'
  >
    {props.text ? props.text : 'Save'} {props.saving ? <Spinner className='ml-1' size="sm" color="dark" /> : null}
  </Button>
)

export default SaveButton