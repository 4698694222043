import React, { useState } from 'react'
import { Button } from 'reactstrap'
import { ColumnControl as TableColumnControl } from '@imcva/reactstrap-table'

const ColumnControl: React.FC = (props) => {
  const [ open, setOpen ] = useState(false)

  const toggle = () => setOpen(o => !o)

  return (
    <>
      <Button onClick={toggle}>Controls</Button>
      <TableColumnControl open={open} toggle={toggle} />
    </>
  )
}

export default ColumnControl
