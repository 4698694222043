import Amplify, { Auth } from 'aws-amplify'

const region = 'us-east-1'

export default () => {
  const {
    REACT_APP_AUTHREDIRECT_URL,
    REACT_APP_API_URL
  } = process.env
  Amplify.configure({
    API: {
      endpoints: [{
        endpoint: REACT_APP_API_URL || 'http://localhost:4000',
        name: 'hris',
        region,
        custom_header: async () => { 
          try {
            return {
              env : process.env.REACT_APP_API_ENV,
              Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
            } 
          } catch (e) {
            console.log(e)
            return {}
          }
        }  
      }]
    },
    Auth: {
      identityPoolId: 'us-east-1:e4b2bb15-b0ca-4d08-a7cc-063dc1168152',
      region,
      userPoolId: 'us-east-1_92zcmy9mM',
      userPoolWebClientId: '6ja9vo8u1hb1o9th300je4491m'
    }
  }) 
  Auth.configure({
    oauth: {
      domain: 'imc-hris.auth.us-east-1.amazoncognito.com',
      scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
      redirectSignIn: REACT_APP_AUTHREDIRECT_URL,
      redirectSignOut: REACT_APP_AUTHREDIRECT_URL,
      responseType: 'code'
    }
  })
}
