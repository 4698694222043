import React, { useState } from 'react'
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

interface InfoProps {

}

const Info: React.FC<InfoProps> = (props) => {
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  return (
    <>
      <FontAwesomeIcon icon={faInfoCircle} onClick={toggle} className='cursor-pointer' />
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Metric Report Info</ModalHeader>
        <ModalBody>
          This report calculates the performance of each distribution during the selected timeframe.  It first determines which positions were open during the selected period using the Job Description Received date to assign the open date and the Closed or Filled date for the closed date for the position.  Candidate counts are then calculated based on when candidates were received for these positions.
        </ModalBody>
      </Modal>
    </>
  )
}

export default Info
