import axios from 'axios'

type DeleteAttachment = (id: string) => Promise<{}>

/**
 * Delete a single attachment by Id
 */
const deleteAttachment: DeleteAttachment = async (id) => {
  const result = await axios.delete(
    `/attachments/${id}`,
  )
  return result.data
}

export default deleteAttachment
