import React from 'react'
import { Link, RouteComponentProps } from 'react-router-dom'
import { Row, Col, Button } from 'reactstrap'
import { TableContext, Table, GlobalSearch, globalSearchFilter, useGlobalFilter } from '@imcva/reactstrap-table'
import { useSortBy, useColumnOrder } from 'react-table'

import useItems from '../../../../hooks/useItems'
import ColumnControl from './ColumnControl'
import columns from './columns'
import Loading from '../../../../components/Loading'
import Position from '@imcva/imc-hris-common/types/position'
import { useAuth } from '../../../../hooks/useAuth'
import useLookup from '../../../../hooks/useLookup'
import { Position_Status } from '@imcva/imc-hris-common/types'

const List: React.FC<RouteComponentProps> = (props) => {
  const [ items, , loading ] = useItems<Position<'populate'>>('/cat/positions')
  const [ statuses, , loadingStatuses ] = useLookup<Position_Status>('Position_Status')
  const { checkPermissions } = useAuth()

  const columnsMemo = React.useMemo(() => {
    const statusList = statuses.map(s => s.name)
    return columns(statusList)
  }, [statuses])

  return (
    <TableContext<Position<'populate'>> 
      options={{ 
        columns: columnsMemo,
        data: items,
        initialState: {
          hiddenColumns: [
            'priority',
            'hiring_manager',
            'job_desc_received',
            'job_desc_finalized',
            'job_desc_posted',
            'location',
            'required_clearance',
            'notes',
            'createdAt',
            'updatedAt'
          ]
        },
        globalFilter: globalSearchFilter<Position<'populate'>>(columnsMemo)
      }}
      storageKey='cat-positions-list'
      plugins={[useGlobalFilter, useSortBy, useColumnOrder]}
    >
      <Row className='mb-3'>
        <Col>
          {checkPermissions('CAT_EDIT')
            ? (
              <Button tag={Link} to='/cat/positions/new'>New Position</Button>
            ) : null
          }
        </Col>
        <Col>
          <Row className=''>
            <Col sm='auto' className='px-1'>
              <ColumnControl />
            </Col>
            <Col className='px-1'>
              <GlobalSearch />
            </Col>
          </Row>
        </Col>
      </Row>
      <Loading loading={loading || loadingStatuses}>
        <Table responsive stickyHeader responsiveHeight='calc(-125px + 100vh)' />
      </Loading>
    </TableContext>
  )
}

export default List
