import axios from 'axios'
import { AttachmentQuery } from '@imcva/imc-hris-common/types'

type GetAttachments = (
  type: string,
  link: string | number,
  additional?: Record<string, string>
) => Promise<AttachmentQuery>

const getAttachments: GetAttachments = async (type, link, additional) => {
  if (type && link) {
    let url = `/attachments/list`
    const results = await axios.post(url, {
      type,
      link,
      additional
    })
    if (results.data.success && results.data.items) {
      return results.data.items
    } else if (!results.data.success && results.data.message) {
      throw new Error(results.data.message)
    } else {
      throw new Error('An unexpected error occurred!')
    }
  }
}

export default getAttachments
