import React from 'react'
import format from 'date-fns/format'
import isValid from 'date-fns/isValid'
import formatDistance from 'date-fns/formatDistance'

interface DateFormatProps {
  value: string | number | Date,
  format?: string
}

const DateFormat: React.FC<DateFormatProps> = (props) => {
  const f = props.format  || 'Pp'
  const value = props.value
  const d = new Date(value)
  if (value !== null && isValid(d)) {
    const formatted = format(d, f)
    const ago = formatDistance(d, new Date(), { addSuffix: true })
    return (
      <span title={ago}>
        {formatted}
      </span>
    )
  }

  return (
    <>{value || null}</>
  )
}

export default DateFormat