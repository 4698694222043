import { useMemo, useContext, useEffect, useState, useRef } from 'react'
import { API } from 'aws-amplify'
import isEqual from 'lodash/isEqual'

import { AlertContext } from './useAlerts'

export interface SelectOption {
  value: string,
  text: string,
  [key: string]: any
}

interface IGetResults<I> {
  success: boolean
  items?: I
}

type useItemsType<I> = [
  I | undefined,
  React.Dispatch<React.SetStateAction<I | undefined>>,
  boolean,
  Record<keyof I, SelectOption[]> | undefined
]

type LookupOptions<Items> = Array<keyof Items | { name: keyof Items, query: object[] }>

const useLookups = <I extends { [key: string]: any } = {}>(lookups: LookupOptions<I>): useItemsType<I> => {
  const path = `/lookups/query`
  const [ items, setItems ] = useState<I | undefined>(undefined)
  const [ loading, setLoading ] = useState(true)
  const { addAlert } = useContext(AlertContext)

  const lookupsRef = useRef<LookupOptions<I> | undefined>(undefined)

  const select = useMemo(() => {
    if (items) {
      const selectMap = Object.keys(items).reduce((prev, current) => {
        const item = items[current]
        prev[current] = lookupSelectMap(item)
        return prev
      }, {} as any)
      return selectMap as { [key in keyof I]: SelectOption[]}
    }
    return undefined
  }, [items])

  useEffect(() => {
    const getItems = async () => {
      setLoading(true)
      setItems(undefined)
      try {
        const results: IGetResults<I> = await API.post('hris', path, {
          body: {
            lookups: lookupsRef.current
          }
        })
        if (results && results.success && results.items) {
          setItems(results.items)
        }
      } catch (error) {
        addAlert(`Could not load items! (${error.message})`)
      } finally {
        setLoading(false)
      }
    }

    if (path) {
      if (!isEqual(lookups, lookupsRef.current)) {
        lookupsRef.current = lookups
        getItems()
      }
    } else {
      setItems(undefined)
      setLoading(false)
    }
  }, [lookups, path, addAlert])

  return [items, setItems, loading, select]
}

const getLookupText = <L extends { _id?: string; [key: string]: any }>(lookup: L[] | undefined, value: string | undefined): L | undefined => {
  return lookup !== undefined && value !== undefined ? lookup.find(c => c._id === value) : undefined
}

const lookupSelectMap = <L extends { _id: string; name: string, [key: string]: any }>(lookup: L[]): SelectOption[] => {
  return lookup.map(l => ({ ...l, text: l.name, value: l._id }))
}

export default useLookups
export {getLookupText, lookupSelectMap}
