import React, { useState, useMemo } from 'react'
import { Row, Col } from 'reactstrap';
import { FormikHelpers, FormikProps } from 'formik';
import { Formik } from '@imcva/formik-reactstrap-widgets'
import { useHistory } from 'react-router-dom';
import { API } from 'aws-amplify'
import Position from '@imcva/imc-hris-common/types/position';
import Distribution from '@imcva/imc-hris-common/types/distribution';

import useItems from '../../../../hooks/useItems'
import useTitle from '../../../../hooks/useTitle';
import useLookups from '../../../../hooks/useLookups';
import { useAlerts } from '../../../../hooks/useAlerts';

import Loading from '../../../../components/Loading';

import { Lookups } from './lookups.d'
import BulkForm from './BulkForm';

interface BulkValue {
  position: Distribution['position'],
  date_posted: Distribution['date_posted'],
  subcategories: Record<string, boolean>
}

const Bulk: React.FC = () => {
  const [ positions, , positionsLoading ] = useItems<Position>('/cat/positions')
  const [ saving, setSaving ] = useState(false)
  const history = useHistory()
  const { addAlert } = useAlerts()
  const [ lookups, , loadingLookups ] = useLookups<Lookups>([
    'Distribution_Category',
    'Distribution_Subcategory'
  ])
  useTitle('Bulk Add Distributions')

  const positionOptions = useMemo(() => {
    const options = positions.map(position => {
      return {
        text: `(${position.id}) ${position.title}`,
        value: position._id
      }
    })
    return options
  }, [positions])

  const saveItem = async (values: Partial<BulkValue>, formik: FormikHelpers<Partial<BulkValue>>) => {
    try {
      let items: Array<Partial<Distribution> | null> = []
      setSaving(true)
      if (values.subcategories && lookups?.Distribution_Subcategory) {
        items = Object.keys(values.subcategories).map(subcategory => {
          const value = values.subcategories ? values.subcategories[subcategory] : false
          if (value === true) {
            const sub = lookups.Distribution_Subcategory.find(sub => sub._id === subcategory)
            return {
              position: values.position,
              category: sub?.category,
              subcategory: sub?._id,
              date_posted: values.date_posted,
            }
          }
          return null
        }).filter(item => item !== null)
      }
      if (items.length > 0) {
        let url = '/cat/distributions/bulk'
        const results = await API.post('hris', url, {
          body: {
            distributions: items
          }
        })
        if (results) {
          const all = '/cat/distributions'
          const positionFiltered = `/cat/positions/${values.position}/distributions`
          const url = values.position ? positionFiltered : all
          history.push(url)
        }
      }
    } catch (error) {
      addAlert(`Could not save item! (${error.message})`)
    } finally {
      setSaving(false)
    }
    setSaving(false)
  }

  const subcategoriesCheckboxOptions = useMemo(() => {
    if(lookups?.Distribution_Category && lookups?.Distribution_Subcategory) {
      const subcateogries = lookups.Distribution_Subcategory.map(subcategory => {
        const category = lookups.Distribution_Category.find((category) => category._id === subcategory.category)
        const text = [category?.name, subcategory.name].filter(v => !!v).join(' - ')
        return {
          name: `subcategories[${subcategory._id}]`,
          text: text || 'Unknown'
        }
      }).sort((a, b) => {
        if (a.text < b.text) { return -1; }
        if (a.text > b.text) { return 1; }
        return 0;
      })
      return subcateogries
    }
    return []
  }, [lookups])

  return (
    <Loading loading={positionsLoading || loadingLookups}>
      <Row>
        <Col className='mx-auto font-weight-bold' xl={6} lg={8} md={10} sm={12}>
          <Formik
            onSubmit={(values, formik) => saveItem(values, formik)}
            enableReinitialize
            initialValues={{}}
            render={(props: FormikProps<Partial<BulkValue>>) => {
              return (
                <BulkForm 
                  formik={props} 
                  saving={saving}
                  options={{
                    positions: positionOptions,
                    distributions: subcategoriesCheckboxOptions
                  }}
                />
              )
            }}
          />
        </Col>
      </Row>
    </Loading>
  )
}

export default Bulk
