import React from 'react'
import { Progress } from 'reactstrap'

interface LoadingProps {
  loading: boolean
}

const Loading: React.FC<LoadingProps> = (props) => {

  if(props.loading) {
    return (
      <Progress bar animated value="100">Loading...</Progress>
    )
  } else {
    return (
      <>
        {props.children}
      </>
    )
  }
}

export default Loading
