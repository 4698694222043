import React from 'react'
import { Route, RouteProps } from 'react-router-dom'

import { useAuth } from '../hooks/useAuth'
import Unauthorized from './Unauthorized'

interface AuthRouteProps extends RouteProps {
  permissionsKey: string
}

const AuthRoute: React.FC<AuthRouteProps> = (props) => {
  const { checkPermissions } = useAuth()
  if (checkPermissions(props.permissionsKey)) {
    return (
      <Route {...props} />
    )
  } else {
    return (
      <Unauthorized />
    )
  }
}

export default AuthRoute
