import React from 'react'
import { IMetrics, ISubmetrics } from './types'
import MetricCount from './MetricCount'

interface MetricCellsProps {
  data: IMetrics | ISubmetrics
  colorOverride?: string
  backgroundColor: boolean
}

const cells = [
  {
    key: 'round1',
    color: 'primary'
  },
  {
    key: 'round2',
    color: 'success'
  },
  {
    key: 'round3',
    color: 'danger'
  },
  {
    key: 'withdrew',
    color: 'warning'
  },
  {
    key: 'accepted',
    color: 'info'
  },
]

const MetricCells: React.FC<MetricCellsProps> = (props) => {
  const { data, colorOverride, backgroundColor } = props

  return (
    <>
      {cells.map(cell => {
        return (
          <td className={ backgroundColor ? 'p-0' : ''}>
            <MetricCount
              value={data[cell.key]}
              percentageTotal={data.submitted}
              showBackground={backgroundColor}
              backgroundColor={colorOverride !== undefined ? colorOverride : cell.color }
            />
          </td>
        )
      })}
    </> 
  )
}

export default MetricCells
