import axios from 'axios'

interface Data {
  type?: string
  subtype?: string
  link?: string
  model?: string
  additional?: Record<string, string>
}

type UploadProgress = (percentage: number) => void

type UploadAttachment = (
  id: string,
  data: Data
) => Promise<{ status: string } | undefined>

/**
 * Update a single attachment by the attachment id.
 * 
 * @param {String} id - The ObjectId of the Attachment 
 * @param {Object} data - An object containing the attachment query info
 * @param {File} data.file - The file object to upload.
 * @param {String} data.type - The ObjectId of the Attachment Type
 * @param {String} data.subtype - The ObjectId of the Attachment Subtype
 * @param {String} data.link - The ObjectId of linking object
 * @param {String} data.model - The model name of the linking object
 * @param {Object} [data.additional] - An object that can be used for additonal filtering
 * 
 * @returns {Promise<{success: boolean, result: Attachment}>}
 */
const updateAttachment: UploadAttachment = async (id, data) => {
  return axios.post(`/attachments/update/${id}`, {
     link: data.link,
     type: data.type,
     subtype: data.subtype,
     model: data.model,
     additional: data.additional,
  })
}

export default updateAttachment
