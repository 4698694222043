import React, { useMemo, useEffect, useState } from 'react'
import { Row, Col } from 'reactstrap';
import { Form as FormikForm, FormikHelpers, FormikProps } from 'formik';
import { Formik, Input, Select, DatePicker, Radio, Checkbox } from '@imcva/formik-reactstrap-widgets'
import { API } from 'aws-amplify'
import { Link, useHistory, useParams } from 'react-router-dom';
import Position from '@imcva/imc-hris-common/types/position'

import useItem from '../../../../hooks/useItem';
import { useAlerts } from '../../../../hooks/useAlerts';
import useTitle from '../../../../hooks/useTitle';
import { useAuth } from '../../../../hooks/useAuth';
import useLookups, { getLookupText, lookupSelectMap } from '../../../../hooks/useLookups';
import useAttachments from '../../../../hooks/useAttachments';

import RemoveButton from '../../../../components/FormHelpers/RemoveButton'
import SaveButton from '../../../../components/FormHelpers/SaveButton'
import Title from '../../../../components/FormHelpers/Title'
import Authorized from '../../../../components/Authorized';
import Attachments from '../../../../components/Attachments'
import Loading from '../../../../components/Loading';

import { Lookups } from './lookups.d'
import useContractPermissions from '../../useContractPermissions'

interface FormProps {
  plaintext?: boolean
}

const Form: React.FC<FormProps> = (props) => {
  const { plaintext } = props
  const { id } = useParams<{ id?: string }>()
  const history = useHistory()
  const { checkPermissions } = useAuth()
  const { addAlert } = useAlerts()
  const [item, setItem, loading, removeItem] = useItem<Position>(id ? `/cat/positions/${id}` : undefined)
  const [saving, setSaving] = useState(false)
  const [ contractAuth, loadingContractAuth ]= useContractPermissions(item?.contract)
  const uploadData = useMemo(() => ({
    link: Number(id),
    type: '6026a8985391d13c5088002c',
    model: 'Position',
  }), [id])
  const [ attachmentsState, attachmentsMethods ] = useAttachments(uploadData)

  useEffect(() => {
    if(
      uploadData.type !== undefined &&
      uploadData.link !== undefined
    ) {
      const get = attachmentsMethods.get
      get()
    }
  }, [uploadData, attachmentsMethods.get])

  const [lookups, , loadingLookups] = useLookups<Lookups>([
    "Position_Status",
    "Position_Priority",
    "Cat_Contract",
    "Contract_Type",
    "Clearance",
    "Program_Manager",
    "Hiring_Manager"
  ])

  const getTitle = () => {
    if (id) {
      if (item?.id) {
        return `${item.id} | Edit Position`
      } else {
        return 'Edit Position'
      }
    } else {
      return 'Add Position'
    }
  }
  useTitle(getTitle(), 'Tracker')

  const savePosition = async (values: Partial<Position>, formik: FormikHelpers<Partial<Position>>) => {
    try {
      setSaving(true)
      let url = '/cat/positions'
      url = id ? url + `/${id}` : url
      if(!attachmentsState.canSave) {
        throw new Error('Invalid attachment. Please try again!')
      }
      const results = await API.post('hris', url, {
        body: values
      })
      if (results) {
        const item = results.item
        if(item._id && attachmentsState.canSave) {
          await attachmentsMethods.save({ link: Number(item._id) })
        }
        setItem(item)
        formik.resetForm()
        history.push('/cat/positions')
      }
    } catch (error) {
      addAlert(`Could not save item! (${error.message})`)
    } finally {
      setSaving(false)
    }
  }

  const remove = async () => {
    const removed = await removeItem()
    if (removed) {
      history.push('/cat/positions')
    }
  }

  const status_date = (status: string) => {
    switch (status) {
      case "Filled":
        return (
          <DatePicker name='filled_date' label='Filled On' />
        )
      case "Closed":
        return (
          <DatePicker name='closed_date' label='Closed On' />
        )
      default:
        return null
    }
  }

  const authorized = plaintext ? !!(contractAuth || checkPermissions('CAT_VIEW')) : !!checkPermissions('CAT_EDIT')

  return (
    <Loading loading={loading || loadingLookups || loadingContractAuth}>
      <Authorized authorized={authorized}>
        <Row className='mb-5'>
          <Col className='mx-auto font-weight-bold' xl={6} lg={8} md={10} sm={12}>
            <Formik
              onSubmit={(values, formik) => savePosition(values, formik)}
              initialValues={item || {}}
              enableReinitialize
              plaintext={plaintext}
              render={(props: FormikProps<Partial<Position>>) => {
                const { values } = props
                const dirty = props.dirty || attachmentsState.dirty
                return (
                  <FormikForm autoComplete="off">
                    <Title
                      id={id}
                      plaintext={plaintext}
                      auth='CAT_EDIT'
                      titleText={{
                        new: 'New Position',
                        edit: `Position - (${item?.id}) ${values.title}`,
                        save: `Editing - (${item?.id}) ${values.title}`
                      }}
                      actionProps={{
                        save: {
                          dirty: dirty,
                          saving,
                          onClick: () => {
                            props.submitForm()
                          }
                        },
                        edit: {
                          tag: Link,
                          to: `/cat/positions/${id}/edit`
                        }
                      }}
                    />
                    <Input name='title' label='Title' />
                    <Select
                      name='priority'
                      label='Priority'
                      InsertBlank
                      options={lookupSelectMap(lookups?.Position_Priority || [])}
                    />
                    <Select
                      name='status'
                      label='Status'
                      InsertBlank
                      options={lookupSelectMap(lookups?.Position_Status || [])}
                    />
                    {status_date(
                      getLookupText(
                        lookups?.Position_Status || [],
                        values?.status || ''
                      )?.name || ''
                    )}
                    <Row>
                      <Col md={4}>
                        <DatePicker name='job_desc_received' label='Job Description Received' />
                      </Col>
                      <Col md={4}>
                        <DatePicker name='job_desc_finalized' label='Job Description Finalized' />
                      </Col>
                      <Col md={4}>
                        <DatePicker name='job_desc_posted' label='Job Description Posted' />
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <DatePicker name='pricing.requested' label='Pricing Requested' />
                      </Col>
                      <Col md={6}>
                        <DatePicker name='pricing.received' label='Pricing Received' />
                      </Col>
                    </Row>
                    <Input name='location' label='Location' />
                    <Select
                      name='contract'
                      label='Contract'
                      InsertBlank
                      options={lookupSelectMap(lookups?.Cat_Contract || [])}
                    />
                    {
                      values.contract && values.contract === 'Custom'
                        ? <Input name='contract_custom' label='Custom Contract' />
                        : null
                    }
                    <Select
                      name='contract_type'
                      options={lookupSelectMap(lookups?.Contract_Type || [])}
                      label='Contract Type'
                      InsertBlank
                    />
                    <Select
                      name='required_clearance'
                      options={lookupSelectMap(lookups?.Clearance || [])}
                      label='Required Clearance'
                      InsertBlank
                    />
                    <Select
                      name='program_manager'
                      label='Program Manager'
                      InsertBlank
                      options={lookupSelectMap(lookups?.Program_Manager || [])}
                    />
                    <Select
                      name='hiring_manager'
                      label='Hiring Manager'
                      InsertBlank
                      options={lookupSelectMap(lookups?.Hiring_Manager || [])}
                    />
                    <Row>
                      <Col md={6}>
                        <Input name='bill_rate.min' label='Bill Rate (Min)' type='number' />
                      </Col>
                      <Col md={6}>
                        <Input name='bill_rate.max' label='Bill Rate (Max)' type='number' />
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <Input name='salary.min' label='Salary (Min)' type='number' />
                      </Col>
                      <Col md={6}>
                        <Input name='salary.max' label='Salary (Max)' type='number' />
                      </Col>
                    </Row>
                    <Checkbox
                      multiple
                      button
                      label='Type'
                      color='primary'
                      className='text-left'
                      outline
                      options={[
                        { className: 'mx-2', name: 'temp_to_perm', value: 'Temp to Perm', text: 'Temp to Perm' },
                        { className: 'mx-2', name: 'direct_hire', value: 'Direct Hire', text: 'Direct Hire' },
                      ]}
                    />
                    <Radio
                      name='backfill'
                      button
                      label='Backfill'
                      color='primary'
                      outline
                      className='mx-2'
                      options={[
                        { className: 'mx-2', value: true, text: 'Yes' },
                        { className: 'mx-2', value: false, text: 'No' },
                      ]}
                    />
                    <Attachments
                      title='Attachments'
                      state={attachmentsState}
                      methods={attachmentsMethods}
                      edit={!plaintext && checkPermissions('CAT_EDIT')}
                    />
                    <Input name='keywords' label='Keywords' />
                    <Input name='notes' label='Notes' type='textarea' rows='10' />
                    {id ? (
                      <Row>
                        <Col md={6}>
                          <DatePicker plaintext datePickerProps={{ dateFormat: "MM/dd/yyyy h:mm aa" }} name='createdAt' label='Created On:' />
                        </Col>
                        <Col md={6}>
                          <DatePicker plaintext datePickerProps={{ dateFormat: "MM/dd/yyyy h:mm aa" }} name='updatedAt' label='Last Updated On:' />
                        </Col>
                      </Row>
                    ) : null}
                    { !plaintext && checkPermissions('CAT_EDIT')
                      ? (
                        <>
                          <Row>
                            <Col>
                              <SaveButton block dirty={dirty} saving={saving} />
                            </Col>
                            {id
                              ? (
                                <Col md={3}>
                                  <RemoveButton
                                    onClick={remove}
                                    title={`(${values.id}) ${values.title}`}
                                  />
                                </Col>
                              ) : null
                            }
                          </Row>
                        </>
                      ) : null
                    }
                  </FormikForm>
                )
              }}
            />
          </Col>
        </Row>
      </Authorized>
    </Loading>
  )
}

export default Form
