import { useContext, useEffect, useState } from 'react'
import { API } from 'aws-amplify'

import { AlertContext } from './useAlerts'

interface IGetResults<I> {
  success: boolean
  items?: Array<I>
}

type useItemsType<I> = [
  Array<I>,
  React.Dispatch<React.SetStateAction<Array<I>>>,
  boolean
]

const useLookup = <I extends {}>(lookup: string, query?: object[]): useItemsType<I> => {
  const path = `/lookups/${lookup}/query`
  const [ items, setItems ] = useState<Array<I>>([])
  const [ loading, setLoading ] = useState(true)
  const { addAlert } = useContext(AlertContext)

  useEffect(() => {
    const getItems = async () => {
      setLoading(true)
      setItems([])
      try {
        const results: IGetResults<I> = await API.post('hris', path, {
          body: query ? { query } : undefined
        })
        if (results && results.success && results.items) {
          setItems(results.items)
        }
      } catch (error) {
        addAlert(`Could not load items! (${error.message})`)
      } finally {
        setLoading(false)
      }
    }
    if (path) {
      getItems()
    } else {
      setItems([])
      setLoading(false)
    }
  }, [query, path, addAlert])

  return [items, setItems, loading]
}

export default useLookup
