import { AttachmentQuery, AttachmentSubtype } from "@imcva/imc-hris-common/types"
import { nanoid } from "nanoid"

type UpdateArray = <T extends Record<string, any>>(
  index: number,
  list: T[],
  update: Partial<T>
) => T[]

const updateArray: UpdateArray = (index, list, update) => {
  return [
    ...list.slice(0, index),
    { ...list[index], ...update},
    ...list.slice(index + 1)
  ]
}

export interface NewAttachment {
  _tempid: string,
  file?: File,
  filename?: string
  progress?: number
  subtype?: string
}

export interface AttachmentData {
  type: string
  subtype?: string
  link: string | number
  model: string
  additional?: Record<string, string>
}

export interface AttachmentItem extends AttachmentQuery {
  delete?: boolean
}

export interface AttachmentState {
  attachments: Array<AttachmentItem | NewAttachment>
  subtypes: Array<AttachmentSubtype>
  loading: boolean
  data: Partial<AttachmentData> 
  canSave: boolean
  dirty: boolean
}

export type AttachmentAction = 
    { type: 'attachments', payload: AttachmentItem[] }
  | { type: 'loading', payload?: boolean }
  | { type: 'delete', index: number }
  | { type: 'undelete', index: number }
  | { type: 'updateData', payload: Partial<AttachmentData> }
  | { type: 'subtypes', payload: Array<AttachmentSubtype> }
  | { type: 'add' }
  | { type: 'onFileChange', index: number, payload: File | undefined }
  | { type: 'onSubtypeChange', index: number, payload: string }
  | { type: 'progress', index: number, payload: number }


export type AttachmentReducer = (
  state: AttachmentState,
  action: AttachmentAction
) => AttachmentState

const switcher: AttachmentReducer = (state, action) => {
  switch(action.type) {
    case 'attachments':
      return { ...state, attachments: action.payload, loading: false }
    case 'loading':
      return {
        ...state,
        loading: 
          action.payload !== undefined ? action.payload : !state.loading
      }
    case 'delete':
      if(state.attachments[action.index]) {
        if("_id" in state.attachments[action.index]) {
          return { 
            ...state,
            attachments: updateArray<AttachmentItem | NewAttachment>(action.index, state.attachments, { delete: true })
          }
        } else {
          return { 
            ...state,
            attachments: [
              ...state.attachments.slice(0, action.index),
              ...state.attachments.slice(action.index + 1)
            ]
          }
        }
      }
      return state
    case 'undelete':
      return { ...state, attachments: updateArray(action.index, state.attachments, { delete: false }) }
    case 'updateData':
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload
        }
      }
    case 'add':
      return { ...state, attachments: [ ...state.attachments, { _tempid: nanoid() }]}
    case 'subtypes':
      return { ...state, subtypes: action.payload }
    case 'onFileChange':
      return { 
        ...state,
        attachments: updateArray(
          action.index,
          state.attachments,
          { file: action.payload, filename: action.payload?.name }
        )
      }
    case 'onSubtypeChange':
      return { 
        ...state,
        attachments: updateArray(
          action.index,
          state.attachments,
          { subtype: action.payload === '' ? undefined : action.payload }
        )
      }
    case 'progress':
      return { 
        ...state,
        attachments: updateArray(
          action.index,
          state.attachments,
          { progress: action.payload }
        )
      }
    default:
      return state
  }
}

const isDirty = (state: AttachmentState) => {
  const dirtyAttachment = state.attachments.find(attachment => {
    const del = "_id" in attachment  && attachment.delete
    const save = !("_id" in attachment)
    const subtype = "_id" in attachment && attachment.originalSubtype !== attachment.subtype
    return del || save || subtype
  })
  return dirtyAttachment !== undefined
}

const canSave = (state: AttachmentState) => {
  if(Array.isArray(state.attachments)) {
    const badSize = state.attachments.find(a => {
      if("file" in a) {
        return a.file?.size !== undefined && a.file.size > 100 * 1024 * 1024
      }
      return false
    })
    if(badSize !== undefined) {
      return false
    }
  }
  return true
}

const attachmentReducer: AttachmentReducer = (state, action) => {
  const newState = switcher(state, action) 
  const dirty = isDirty(newState)
  const save = canSave(newState)
  return { ...newState, dirty, canSave: save }
}

export default attachmentReducer
