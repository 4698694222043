import React, { ReactElement } from 'react'
import { Link } from 'react-router-dom'
import { Dollar, NumberRange, TextBackground, DateFormat, CellBackground } from '../../../../components/TableHelpers'
import { Column as ReactTableColumn, UseSortByColumnOptions, CellProps } from 'react-table'
import { ExtendedCandidate } from './types'
import AttachmentCount from '../../../../components/AttachmentCount'

type Cell<V = any> = CellProps<ExtendedCandidate, V>

interface ExtendedOptions {
  className?: string
  Cell?: (props: Cell<string>) => ReactElement | ReactElement[] | string | number | null 
}

type Column = ReactTableColumn<ExtendedCandidate> & UseSortByColumnOptions<ExtendedCandidate> & ExtendedOptions

const danger = '#f8d7da'

const columns: Column[] = [
  {
    id: 'attachments',
    Header: 'Attachments',
    accessor: 'attachment_count',
    className: 'text-center align-middle',
    Cell: (cell: any) => {
      return <AttachmentCount value={cell.value} />
    }
  },
  {
    id: 'full_name',
    Header: 'Full Name',
    className: 'text-left p-0',
    accessor: d => {
      const { name } = d
      if (name) {
        const full_name =  [d.name?.last, d.name?.first].filter(n => !!n)
        return full_name.length > 0 ? full_name.join(', '): '(No Name)'
      }
      return '(No Name)'
    },
    Cell: (props) => {
      const { row, cell } = props
      const { original } = row
      const color = original.substatus?.color
      if (original._id) {
        return (
          <CellBackground color={color}>
            <Link to={`/cat/candidates/${original._id}`}>{cell.value}</Link>
          </CellBackground>
        )
      }
      return (<CellBackground color={color}>{cell.value}</CellBackground>)
    }
  },
  {
    id: 'position',
    Header: 'Position',
    accessor: d => {
      const { position } = d
      if (position) {
        return `(${position.id}) ${position.title}`
      }
      return null
    }
  },
  {
    id: 'distribution_category',
    Header: 'Distribution Category',
    accessor: d => d.distribution?.category?.name || null,
  },
  {
    id: 'distribution_subcategory',
    Header: 'Distribution Subcategory',
    accessor: d => d.distribution?.subcategory?.name || null
  },
  {
    id: 'resume',
    Header: 'Resume',
    className: 'text-nowrap',
    accessor: d => {
      const { resume, received_on, rejection_date } = d
      const { sent, reviewed, reviewed_by } = resume || {}
      return `Recived On: ${received_on}, Sent: ${sent}, Reviewd: ${reviewed}, Reviewed By: ${reviewed_by?.name}, Rejection Date: ${rejection_date}`
    },
    Cell: ({ row: { original } }) => {
      const { resume, received_on,  rejection_date } = original
      const { sent, reviewed, reviewed_by } = resume || {}
      const waiting = original.substatus?.name === 'Waiting On Review'
      return (
        <>
          <TextBackground label='Received On' highlight={!received_on && waiting} highlightColor={danger}>
            <DateFormat value={received_on} format='P' />
          </TextBackground>
          <TextBackground label='Sent' highlight={!sent && waiting} highlightColor={danger}>
            <DateFormat value={sent} format='P' />
          </TextBackground>
          <TextBackground hide={!sent} label='Reviewed' highlight={!reviewed && waiting} highlightColor={danger}>
            <DateFormat value={reviewed} format='P' />
          </TextBackground>
          <TextBackground hide={!reviewed_by && !reviewed} label='Reviewed By' highlight={reviewed && !reviewed_by && waiting} highlightColor={danger}>
            {reviewed_by?.name}
          </TextBackground>
          <TextBackground hide={!rejection_date} label='Rejection Date' highlight={false} highlightColor={danger}>
            <DateFormat value={rejection_date} format='P' />
          </TextBackground>
        </>
      )
    }
  },
  {
    Header: 'Rejection Date',
    accessor: 'rejection_date',
    className: 'text-nowrap',
    Cell: (d) => <DateFormat value={d.cell.value} format='P' />
  },
  { 
    id: 'received_on',
    Header: 'Resume - Received On',
    accessor: 'received_on',
    Cell: ({ cell: { value }}) => (
      <TextBackground label='' highlight={!value} highlightColor={danger}>
        <DateFormat value={value} format='P' />
      </TextBackground>
    ),
  },
  { 
    id: 'resume_sent',
    Header: 'Resume - Sent',
    accessor: d => d.resume?.sent || null,
    Cell: ({ cell: { value }}) => (
      <TextBackground label='' highlight={!value} highlightColor={danger}>
        <DateFormat value={value} format='P' />
      </TextBackground>
    ),
  },
  { 
    id: 'resume_reviewed',
    Header: 'Resume - Reviewed',
    accessor: d => d.resume?.reviewed || null,
    Cell: ({ cell: { value }, row: { original }}) => (
      <TextBackground
        label=''
        highlight={!value && (original.resume?.sent || true)}
        highlightColor={danger}
      >
        <DateFormat value={value} format='P' />
      </TextBackground>
    ),
  },
  { 
    id: 'resume_reviewed_by',
    Header: 'Resume - Reviewed By',
    accessor: d => {
      return d.resume?.reviewed_by?.name || null
    },
    Cell: ({ cell: { value }, row: { original }}) => (
      <TextBackground
        label=''
        highlight={!value && (original.resume?.reviewed_by || true)}
        highlightColor={danger}
      >
        {value}
      </TextBackground>
    ),
  },
  {
    id: 'bill_rate',
    Header: 'Bill Rate',
    className: 'h-100 p-0 text-nowrap',
    accessor: (d) => {
      const bill_rate_max = d.position && d.position.bill_rate ? d.position.bill_rate.max : undefined
      return (
        <NumberRange 
          min={d.bill_rate?.min}
          max={d.bill_rate?.max}
          highlightNumber={bill_rate_max}
          render={(props) => (
            <Dollar value={props.value} fixed />
          )} 
        />
      )
    }
  },
  {
    id: 'salary',
    Header: 'Salary',
    className: 'h-100 p-0 text-nowrap',
    accessor: (d) => {
      return (
        <NumberRange 
          min={d.salary?.min}
          max={d.salary?.max}
          highlightNumber={d.position?.salary?.max}
          render={(props) => (
            <Dollar value={props.value} comma />
          )} 
        />
      )
    }
  },
  {
    Header: 'Rank',
    accessor: d => d.rank?.name,
    className: 'h-100 p-0',
    // sortType: sortWithList(Object.keys(rank_color_map)),
    Cell: ({ cell: { value }, row: { original }}) => ( 
      <CellBackground color={original.rank?.color} >
        {value}
      </CellBackground>
    )
  },
  {
    Header: 'Status',
    accessor: d => d.status?.name,
  },
  {
    Header: 'Sub Status',
    accessor: d => d.substatus?.name,
  },
  {
    id: 'round',
    Header: 'Rounds',
    className: 'text-nowrap',
    accessor: (d) => {
      const { round_1, round_2, round_3 } = d
      return (
        <>
          <TextBackground label='Round 1' hide={!round_1} highlight={false}>
            <DateFormat value={round_1} format='P' />
          </TextBackground>
          <TextBackground label='Round 2' hide={!round_2} highlight={false}>
            <DateFormat value={round_2} format='P' />
          </TextBackground>
          <TextBackground label='Round 3' hide={!round_3} highlight={false}>
            <DateFormat value={round_3} format='P' />
          </TextBackground>
        </>
      )
    }
  },
  {
    Header: 'Round 1',
    accessor: 'round_1',
    Cell: ({ cell: { value } }) => (<DateFormat value={value} format='P' />),
  },
  {
    Header: 'Round 2',
    accessor: 'round_2',
    Cell: ({cell: { value }}) => (<DateFormat value={value} format='P' />),
  },
  {
    Header: 'Round 3',
    accessor: 'round_3',
    Cell: ({ cell: { value } }) => (<DateFormat value={value} format='P' />),
  },
  {
    Header: 'Type',
    accessor: d => d.type?.name,
  },
  {
    Header: 'Location',
    accessor: 'location'
  },
  {
    Header: 'Clearance',
    accessor: d => d.clearance?.name
  },
  {
    id: 'exibit',
    Header: 'Exibit',
    accessor: (d) => {
      const { exhibit } = d
      if (exhibit) {
        const { sent_for_review, hm_approved, contracts_approved, signed, pmo_approved } = exhibit
        return (
          <>
            <TextBackground hide={!sent_for_review} label='Sent For Review' highlight={false}>
              <DateFormat value={sent_for_review} format='P' />
            </TextBackground>
            <TextBackground hide={!sent_for_review} label='HM Approved' highlight={!hm_approved} highlightColor={danger}>
              <DateFormat value={hm_approved} format='P' />
            </TextBackground>
            <TextBackground hide={!sent_for_review} label='Contracts Approved' highlight={!contracts_approved} highlightColor={danger}>
              <DateFormat value={contracts_approved} format='P' />
            </TextBackground>
            <TextBackground hide={!sent_for_review} label='PMO Approved' highlight={!pmo_approved} highlightColor={danger}>
              <DateFormat value={pmo_approved} format='P' /> 
            </TextBackground>
            <TextBackground hide={!sent_for_review || !contracts_approved} label='Signed' highlight={!signed} highlightColor={danger}>
              <DateFormat value={signed} format='P' /> 
            </TextBackground>
          </>
        )
      }
    }
  },
  {
    id: 'offer_letter',
    Header: 'Offer Letter',
    accessor: (d) => {
      if (d.offer_letter) {
        const { extended, signed  } = d.offer_letter
        return (
          <>
            <TextBackground hide={!extended} label='Extended' highlight={false}>
              <DateFormat value={extended} format='P' /> 
            </TextBackground>
            <TextBackground hide={!extended} label='Signed' highlight={!signed} highlightColor={danger}>
              <DateFormat value={signed} format='P' /> 
            </TextBackground>
          </>
        )
      }
    }
  },
  {
    id: 'nda',
    Header: 'NDA',
    accessor: (d) => {
      if (d.nda_signed) {
        const { staffing_agency, candidate  } = d.nda_signed
        return (
          <>
            <TextBackground hide={!staffing_agency} label='Staffing Agency' highlight={false}>
              <DateFormat value={staffing_agency} format='P' /> 
            </TextBackground>
            <TextBackground hide={!candidate} label='Candidate' highlight={false}>
              <DateFormat value={candidate} format='P' /> 
            </TextBackground>
          </>
        )
      }
    },
  },
  {
    id: 'reapply',
    Header: 'Reapply',
    accessor: d => d.reapply !== undefined ? d.reapply ? 'Yes' : 'No' : null
  },
  {
    Header: 'Created On',
    accessor: 'createdAt',
    Cell: ({ cell: { value }}) => (
      <DateFormat value={value} />
    ),
  },
  {
    Header: 'Last Updated On',
    accessor: 'updatedAt',
    Cell: ({ cell: { value }}) => (
      <DateFormat value={value} />
    ),
  }
]

export default columns
