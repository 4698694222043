import React, { useCallback, useEffect, useState, useContext, createContext } from 'react'
import { Alert } from 'reactstrap'

interface IAlertContext {
  alerts: string[]
  addAlert: (alert: string) => void
  resetAlerts: () => void
}

interface IAlertProvider {
  location: object
}

const AlertContext = createContext<IAlertContext>({
  alerts: [],
  addAlert: () => null,
  resetAlerts: () => null
})

const AlertProvider: React.FC<IAlertProvider> = (props) => {
  const { location } = props
  const [alerts, setAlerts] = useState<string[]>([])

  const addAlert = useCallback((alert: string): void => {
    setAlerts(currentAlerts => currentAlerts.concat(alert))
  }, [])

  const resetAlerts = (): void => {
    setAlerts([])
  }

  useEffect(() => {
    // Reset alerts when the router page changes
    setAlerts([])
  }, [location])

  return (
    <AlertContext.Provider value={{ alerts, addAlert, resetAlerts }}>
      {props.children}
    </AlertContext.Provider>
  )
}

const useAlerts = (): IAlertContext => {
  return useContext<IAlertContext>(AlertContext)
}

const Alerts: React.FC = () => {
  const { alerts } = useContext(AlertContext)
  return (
    <>
      {alerts.map((alert, index) => (
        <Alert key={index} color='warning'>
          {alert}
        </Alert>
      ))}
    </>
  )
}

const AlertConsumer = AlertContext.Consumer

export { Alerts, useAlerts, AlertContext, AlertProvider, AlertConsumer }
