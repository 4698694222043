import React, { useState, useEffect } from 'react'
import { Table, Progress } from 'reactstrap'
import { ListProps } from './types'
import { ILookupConfig } from '@imcva/imc-hris-common/types'
import { useRouteMatch, Link } from 'react-router-dom'

const List: React.FC<ListProps> = (props) => {
  const { getLookups, checkPermissions } = props
  const { url } = useRouteMatch()
  const [items, setItems] = useState<ILookupConfig[] | undefined>()

  useEffect(() => {
    const getItems = async () => {
      let result = await getLookups()
      if (checkPermissions) {
        result = result.filter(i => checkPermissions(i.permissionKey))
      }
      setItems(result)
    }
    getItems()
  }, [url, getLookups, checkPermissions])

  if (!items) {
    return (
      <Progress bar animated value="100">Loading...</Progress>
    )
  } else {
    return (
      <Table>
        <thead>
          <tr>
            <th>Name</th>
          </tr>
        </thead>
        <tbody>
          {items.map((item, index) => {
            return (
              <tr key={index}>
                <td>
                  <Link to={`${url}/${item._id}`}>
                    {item.name}
                  </Link>
                </td>
              </tr>
            )
          })}
        </tbody>
      </Table>
    )
  }

}

export default List






