const Dollar = (props) => {
  if (!isNaN(props.value) && props.value !== null) {
    const fixed = props.fixed === true ? 2 : props.fixed
    let value = fixed ? props.value.toFixed(fixed) : props.value
    value = props.comma ? value.toLocaleString('en') : value
    return `$${value}`
  } else {
    return props.value ? props.value : null
  }
}

export default Dollar