import { useEffect, useState } from 'react'
import { API } from 'aws-amplify'

import { useAlerts } from './useAlerts'

interface IGetResults<O> {
  success: boolean
  item: O
}

interface IDelResults {
  success: boolean
}

type useItemType<I> = [
  I | undefined,
  React.Dispatch<React.SetStateAction<I | undefined>>,
  boolean,
  () => Promise<IDelResults>,
  string[]
]

const useItem = <I extends {}>(path: string | undefined): useItemType<I> => {
  const [item, setItem] = useState<I | undefined>(undefined)
  const [loading, setLoading] = useState(true)
  const { alerts, addAlert } = useAlerts()

  const remove = (): Promise<IDelResults> => {
    const errorMessage = new Error('There was an error removing this item. Please try again.')
    return new Promise(async (resolve, reject) => {
      try {
        const removed: IDelResults = await API.del('hris', path, {})
        if (removed.success) {
          resolve(removed)
        } else {
          addAlert(errorMessage.message)
          reject(errorMessage)
        }
      } catch (error) {
        addAlert(errorMessage.message)
        reject(error)
      }
    })
  }

  useEffect(() => {
    const getItem = async () => {
      setLoading(true)
      try {
        const results: IGetResults<I> = await API.get('hris', path, {})
        if (results && results.success && results.item) {
          setItem(results.item)
        }
      } catch (error) {
        let message = error.message
        if (error.response && error.response.data && error.response.data.message) {
          message = error.response.data.message 
        }
        addAlert(`Could not load item! (${message})`)
      } finally {
        setLoading(false)
      }
    }
    if (path) {
      getItem()
    } else {
      setItem(undefined)
      setLoading(false)
    }
  }, [path, addAlert])

  return [item, setItem, loading, remove, alerts ]
}

export default useItem
