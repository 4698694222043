import React from 'react'
import { Route } from 'react-router-dom'
import { API } from 'aws-amplify'

import { useAuth } from '../hooks/useAuth'
import Lookups from '../components/Lookups'
import Login from '../components/Login'
import DefaultRouteRedirect from './DefaultRouteRedirect'
import CAT from './cat'
import Jobdescriptions from './jobdescription'
import AuthRoute from '../components/AuthRoute'
import CustomSwitch from '../components/CustomSwitch'
import useAnalytics from '../hooks/useAnalytics'

const Routes: React.FC = (routerProps) => {
  const { loading, user, checkPermissions } = useAuth()
  useAnalytics()

  if (loading) {
    return null
  } else if (user)  {
    return (
      <CustomSwitch>
        <Route exact path='/' render={(props) => (
            <DefaultRouteRedirect />
          )}
        />
        <AuthRoute permissionsKey="JOB_DESC_DB" path='/jobdescriptions' render={(props) => (
            <Jobdescriptions {...routerProps} {...props} />
          )}
        />
        <AuthRoute permissionsKey='CAT' path='/cat' render={(props) => (
            <CAT {...props} {...routerProps} />
          )}
        />
        <AuthRoute permissionsKey='MANAGE_LOOKUPS' path='/lookups' render={(props) => (
            <Lookups 
              {...props}
              {...routerProps}
              getLookups={async () => {
                const lookups = await API.get('hris', '/lookups', {})
                if (lookups.items) {
                  return lookups.items
                }
              }}
              getOptions={async (id) => {
                const result = await API.post('hris', `/lookups/${id}`, {})
                return result
              }}
              addOption={async (lookup, data) => {
                const result = await API.post('hris', `/lookups/${lookup}/add`, { body: data })
                return result
              }}
              editOption={async (lookup, id, data) => {
                const result = await API.post('hris', `/lookups/${lookup}/${id}`, { body: data })
                return result
              }}
              editConfig={async (lookup, data) => {
                const result = await API.post('hris', `/lookups/${lookup}/config`, { body: data })
                return result
              }}
              getLinks={async (lookup, id) => {
                const result = await API.get('hris', `/lookups/${lookup}/relationships/${id}`, {})
                return result
              }}
              checkPermissions={(permissionKey) => {
                if (permissionKey) {
                  return checkPermissions(permissionKey)
                }
                return true
              }}
            />
          )}
        />
      </CustomSwitch>
    )
  } else {
    return <Login />
  }
}

export default Routes
