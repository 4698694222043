import React from 'react'
import { useRouteMatch, Route } from 'react-router-dom'

import List from './List'
import Form from './Form'
import Bulk from './Form/Bulk'
import AuthRoute from '../../../components/AuthRoute'
import CustomSwitch from '../../../components/CustomSwitch'

const Routes: React.FC = (routerProps) => {
  const { path } = useRouteMatch()

  return (
    <CustomSwitch>
      <Route exact path={`${path}/`} render={(props) => (
        <List {...routerProps} {...props} />
      )} />
      <Route exact path={`${path}/new`} render={(props) => (
        <Form {...routerProps} {...props} />
      )} />
      <AuthRoute permissionsKey='CAT_EDIT' exact path={`${path}/bulk`} render={(props) => (
        <Bulk {...routerProps} {...props} />
      )} />
      <Route exact path={`${path}/:id`} render={(props) => (
        <Form {...routerProps} {...props} plaintext />
      )} />
      <Route exact path={`${path}/:id/edit`} render={(props) => (
        <Form {...routerProps} {...props} />
      )} />
    </CustomSwitch>
  )
}

export default Routes



