import React from 'react'
import { useRouteMatch, Route } from 'react-router-dom'

import Position from './Position'
import Distribution from './Distribution'
import Candidate from './Candidate'
import Reports from './Reports'
import CustomSwitch from '../../components/CustomSwitch'

const Routes: React.FC = (routerProps) => {
  const { path } = useRouteMatch()
  return (
    <CustomSwitch>
      <Route path={`${path}/positions`} render={(props) => (
        <Position {...routerProps} {...props} />
      )} />
      <Route path={`${path}/distributions`} render={(props) => (
        <Distribution {...routerProps} {...props} />
      )} />
      <Route path={`${path}/candidates`} render={(props) => (
        <Candidate {...routerProps} {...props} />
      )} />
      <Route path={`${path}/reports`} render={(props) => (
        <Reports {...routerProps} {...props} />
      )} />
    </CustomSwitch>
  )
}

export default Routes



